<div class="header" [ngClass]="{'disabled' : notAvailable}">
  <div class="i-container">
    <div class="i-row">
      <div class="head-block">
        <div class="menu">
          <div class="logo">
            <a href="/">
              <img class="cursor-pointer" src="assets/images/header/logo.svg" alt="ismet" height="40px">
            </a>
          </div>
          <div class="head-bred">
            <svg width="17" height="16" class="head-bred_icon" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.74554 3.42857C2.69231 3.42857 3.45982 2.66106 3.45982 1.71429C3.45982 0.767512 2.69231 0 1.74554 0C0.798762 0 0.03125 0.767512 0.03125 1.71429C0.03125 2.66106 0.798762 3.42857 1.74554 3.42857Z" fill="#363636"/>
              <path d="M1.74554 9.71422C2.69231 9.71422 3.45982 8.9467 3.45982 7.99993C3.45982 7.05316 2.69231 6.28564 1.74554 6.28564C0.798762 6.28564 0.03125 7.05316 0.03125 7.99993C0.03125 8.9467 0.798762 9.71422 1.74554 9.71422Z" fill="#363636"/>
              <path d="M1.74554 16.0003C2.69231 16.0003 3.45982 15.2328 3.45982 14.2861C3.45982 13.3393 2.69231 12.5718 1.74554 12.5718C0.798762 12.5718 0.03125 13.3393 0.03125 14.2861C0.03125 15.2328 0.798762 16.0003 1.74554 16.0003Z" fill="#363636"/>
              <path d="M8.03264 3.42857C8.97942 3.42857 9.74693 2.66106 9.74693 1.71429C9.74693 0.767512 8.97942 0 8.03264 0C7.08587 0 6.31836 0.767512 6.31836 1.71429C6.31836 2.66106 7.08587 3.42857 8.03264 3.42857Z" fill="#363636"/>
              <path d="M14.3158 3.42857C15.2626 3.42857 16.0301 2.66106 16.0301 1.71429C16.0301 0.767512 15.2626 0 14.3158 0C13.3691 0 12.6016 0.767512 12.6016 1.71429C12.6016 2.66106 13.3691 3.42857 14.3158 3.42857Z" fill="#363636"/>
              <path d="M8.03264 9.71422C8.97942 9.71422 9.74693 8.9467 9.74693 7.99993C9.74693 7.05316 8.97942 6.28564 8.03264 6.28564C7.08587 6.28564 6.31836 7.05316 6.31836 7.99993C6.31836 8.9467 7.08587 9.71422 8.03264 9.71422Z" fill="#363636"/>
              <path d="M14.3158 9.71422C15.2626 9.71422 16.0301 8.9467 16.0301 7.99993C16.0301 7.05316 15.2626 6.28564 14.3158 6.28564C13.3691 6.28564 12.6016 7.05316 12.6016 7.99993C12.6016 8.9467 13.3691 9.71422 14.3158 9.71422Z" fill="#363636"/>
              <path d="M8.03264 16.0003C8.97942 16.0003 9.74693 15.2328 9.74693 14.2861C9.74693 13.3393 8.97942 12.5718 8.03264 12.5718C7.08587 12.5718 6.31836 13.3393 6.31836 14.2861C6.31836 15.2328 7.08587 16.0003 8.03264 16.0003Z" fill="#363636"/>
              <path d="M14.3158 16.0003C15.2626 16.0003 16.0301 15.2328 16.0301 14.2861C16.0301 13.3393 15.2626 12.5718 14.3158 12.5718C13.3691 12.5718 12.6016 13.3393 12.6016 14.2861C12.6016 15.2328 13.3691 16.0003 14.3158 16.0003Z" fill="#363636"/>
            </svg>
            <div class="head-bred_select">
              <div class="head-bred_select__item">
                <a [href]="'https://www.ismet.kz/'" target="_blank">
                  <div class="zoom-block">
                    <img src="assets/images/header/ismet.svg" alt="telecom">
                    <p> {{ 'Ismet' | translate }} </p>
                  </div>
                </a>
              </div>
              <div class="head-bred_select__item">
                <a [href]="translate.currentLang == 'ru' ? 'https://www.ismet.kz/ru/documents/telecom-services' : 'https://www.ismet.kz/kk/documents/telecom-services'" target="_blank">
                  <div class="zoom-block">
                    <img src="assets/images/header/kt-bussines.svg" alt="market">
                    <p>{{ 'KTBussines' | translate }}</p>
                  </div>
                </a>
              </div>
              <div class="head-bred_select__item">
                <a [href]="'https://aitu.cloud/'" target="_blank">
                  <div class="zoom-block">
                    <img src="assets/images/header/cloud.svg" alt="doc">
                    <p>{{'aitu.cloud' | translate}}</p>
                  </div>
                </a>
              </div>
              <div class="head-bred_select__item">
                <a [href]="'https://oofd.kz/'" target="_blank">
                  <div class="zoom-block">
                    <img src="assets/images/header/ofd.svg" alt="ofd">
                    <p>{{ 'OFD' | translate }}</p>
                  </div>
                </a>
              </div>
              <div class="head-bred_select__item">
                <a [href]="'https://markirovka.ismet.kz/'" target="_blank">
                  <div class="zoom-block">
                    <img src="assets/images/header/markirovka.svg" alt="markirovka">
                    <p>{{ 'Marking' | translate }}</p>
                  </div>
                </a>
              </div>
              <div class="head-bred_select__item">
                <a [href]="'https://partner.ismet.kz/'" target="_blank">
                  <div class="zoom-block">
                    <img src="assets/images/header/partner.svg" alt="markirovka">
                    <p>{{ 'Ismet Partner' | translate }}</p>
                  </div>
                </a>
              </div>
              <div class="head-bred_select__item">
                <a [href]="'https://docs.ismet.kz/'" target="_blank">
                  <div class="zoom-block">
                    <img src="assets/images/header/docs.svg" alt="markirovka">
                    <p>{{ 'Ismet Docs' | translate }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div (click)="triggerCatalog()" [ngClass]="{'active': activeIndex == 0}" class="flex header-item gap-1 align-items-center cursor-pointer">
            {{ 'Товары и услуги' | translate }}
            <svg [ngClass]="{'rotate-180': activeIndex == 0}" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7476 7L8.74756 10L5.74756 7" stroke="#363636" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
          </div>

        </div>
        <div class="head-right-block">
          <div class="head-right-block_item"></div>
          <div (click)="goToFavorite()" class="head-right-block_item heart">
            <svg width="16" height="16" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9205 2.07953C13.2296 1.38834 12.2924 1 11.3151 1C10.3378 1 9.40063 1.38834 8.70976 2.07953L7.99982 2.78947L7.28988 2.07953C5.85098 0.640634 3.51807 0.640634 2.07917 2.07953C0.640276 3.51843 0.640276 5.85134 2.07917 7.29024L2.78911 8.00018L7.99982 13.2109L13.2105 8.00018L13.9205 7.29024C14.6117 6.59937 15 5.66215 15 4.68488C15 3.70762 14.6117 2.7704 13.9205 2.07953Z" stroke="#363636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="fav-count" *ngIf="isAuth && headerService.favNumber > 0">
              {{ headerService.favNumber}}
            </div>
          </div>

          <div *ngIf="lang() == 'ru'" (click)="setLang('kz')" href="#" class="lang-switch head-right-block_item">ҚЗ</div>
          <div *ngIf="lang() == 'kz'" (click)="setLang('ru')" href="#" class="lang-switch head-right-block_item">РУ</div>

          <div class="add-btn" [routerLink]="'/create-card'">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_9065_417)">
                <path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_9065_417">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            {{ 'Добавить' | translate }}
          </div>

          <button class="head-right-block_item btn btn-md secondary-btn-color" *ngIf="!isAuth" (click)="login()">{{ 'SignIn' | translate }}</button>

          <div *ngIf="isAuth" class="flex user-data-menu">
            <div class="user-avatar">
              <img [attr.src]="'/proxy/profile-api.bpmn/api/v1/profile/avatar' | secure | async" />
            </div>
            <div class="">
              <div class="user-data">
                <div *ngFor="let item of userData?.companyList" class="user-data-item">
                  <div *ngIf="item?.mainCompany" class="">
                    {{ item.uin }}
                  </div>
                  <div *ngIf="item?.mainCompany" class="company-name">
                    {{ (translate.currentLang == 'ru' ? item.companyName : item.companyNameKz) | shortname }}
                  </div>
                </div>
                <div *ngIf="userData?.companyList.length == 0" class="user-data-item">
                  {{userData?.userInfo?.lastName}} {{userData?.userInfo?.firstName}}
                </div>
                <div class="user-arrow">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 11L8 6L13 11" stroke="#363636" stroke-width="1.5" stroke-linecap="round"/>
                  </svg>
                </div>
              </div>
              <div class="user-menu-wrap">
                <div class="user-menu">
                  <div class="user-info">
                    <div class="user-avatar">
                      <img [attr.src]="'/proxy/profile-api.bpmn/api/v1/profile/avatar' | secure | async" />
                    </div>
                    <div class="user-name">{{userData?.userInfo?.lastName}} {{userData?.userInfo.firstName}}</div>
                    <a class="profile-link"
                       [href]="(window.location.host == 'partner.ismet.kz' ? 'https://www.ismet.kz/' : 'https://test.i-smet.kz') + (translate.currentLang == 'ru' ? 'ru' : 'kk') + '/cabinet#pt'"
                       target="_blank">
                      <div class="edit-profile mt-1">{{ 'EditProfile' | translate }}</div>
                    </a>
                  </div>
                  <div class="pc-btn-wrap text-center">
                    <button *ngIf="userData?.userInfo?.isModerator == false" [routerLink]="['/account/purchases']" pButton class="pc-btn">{{ 'GoToPersonalAccount' | translate }}</button>
                    <button *ngIf="userData?.userInfo?.isModerator == true" [routerLink]="['/moderator']" pButton class="pc-btn moder-btn">{{ 'GoToModeratorAccount' | translate }}</button>
                  </div>
                  <div class="company-list">
                    <p-progressSpinner *ngIf="loadSpinner" [class]="'load-spinner-global'"></p-progressSpinner>
                    <ng-container *ngFor="let item of userData?.companyList; index as i">
                      <div *ngIf="!loadSpinner" (click)="selectCompany(item, i)" [ngClass]="{'active-item': item?.mainCompany}">
                        <div class="company-list-item" *ngIf="item.companyState == 3">
                          <app-company-image [src]="'/assets/images/main/company-filler.svg'" [uin]="item.uin" />
                          <div class="user-company-name">
                            {{ (translate.currentLang == 'ru' ? item?.companyName : item?.companyNameKz) | shortname }}
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div class="company-list-item" [routerLink]="'/attach-company'">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 8H1" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 1V15" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>

                      <div class="user-company-name">{{ 'AddCompany' | translate }}</div>
                    </div>
                  </div>
                  <div class="exit-wrap text-center">
                    <a [href]="logoutLink" class="exit-btn w-full">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 3L3.2 3C2.53726 3 2 3.7835 2 4.75L2 15.25C2 16.2165 2.53726 17 3.2 17L8 17" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13 14L17 10L13 6" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16 10L8 10" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      {{ 'SingOut' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-bar-container relative">
        <div class="accordion-wrap">

          <div class="bg-white catalog-body-wrap" [ngClass]="{'active': activeIndex == 0}">
            <div class="catalog-body">
              <div class="catalog-column">
                <div *ngFor="let item of headerService._catalog;index as i" (mouseover)="setActiveItem(headerService._catalog, i, 1);"
                     (mouseleave)="clearTimeout()" [ngClass]="{'active': item.active}" class="column-item">
                  {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
                  <svg class="ml-auto" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 11L6 6L1 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
              <div class="catalog-column">
                <ng-container *ngFor="let item of headerService._catalog">
                  <div *ngIf="item.active" class="column-header">
                    {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
                  </div>
                </ng-container>
                <div class="column-body">
                  <ng-container *ngFor="let item of headerService._catalog[headerService._typeIndex].parentlist;index as i">
                    <div *ngIf="item.type == headerService._catalog[headerService._typeIndex].type" class="column-item"
                         (mouseleave)="clearTimeout()"
                         (click)="goToSearch(item, i, 2)"
                         [routerLink]="'/search'"
                         (mouseover)="setActiveItem(headerService._catalog[headerService._typeIndex].parentlist, i,2);"
                         [ngClass]="{'active': item.active}">
                      <img class="cat-icon" [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath='+item.icon" alt="">
                      <div class="">
                        {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
                      </div>
                      <svg class="ml-auto" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 11L6 6L1 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="catalog-column" (mouseover)="clearTimeout()">
                <ng-container *ngFor="let item of headerService._catalog[headerService._typeIndex].parentlist" >
                  <div *ngIf="item.active" class="column-header">
                    {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
                  </div>
                  <div *ngIf="item.active" class="column-body">
                    <ng-container *ngFor="let item of headerService._catalog[headerService._typeIndex].parentlist[headerService._parentIndex]?.child_categories;index as i">
                      <div *ngIf="item.type == headerService._catalog[headerService._typeIndex].type" class="column-item" (click)="goToSearch(item, i, 3)" [routerLink]="'/search'">
                        {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>

        </div>
        <div class="black-bg cursor-pointer" [ngClass]="{'active': activeIndex == 0}" (click)="triggerCatalog()"></div>
      </div>
    </div>
  </div>
</div>

<div class="header-mobile">
  <div class="header-wrap">
    <img src="assets/images/header/logo.svg" alt="">
    <img src="assets/images/header/search-icon.svg" (click)="openSearch = true" alt="">
    <img *ngIf="isMobileApp()"
         src="assets/images/header/close-icon.svg"
         (click)="webViewService.sendAction('CLOSE_WEBVIEW')"
         alt="" class="hidden">
  </div>
</div>
<div class="header-search">
  <p-sidebar [(visible)]="openSearch" [modal]="false" [showCloseIcon]="false" [fullScreen]="true">
    <ng-template pTemplate="header">
      <div class="flex search-header-wrap">
        <div (click)="search(); openSearch = false" class="back-arrow">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5329_29354)">
              <path d="M19 11H7.82998L12.71 6.11997C13.1 5.72997 13.1 5.08997 12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z" fill="#363636"/>
            </g>
            <defs>
              <clipPath id="clip0_5329_29354">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="search-header">
          <input class="search-input" placeholder="Поиск в Partner" [(ngModel)]="searchService._name" (change)="search(); openSearch = false" type="search">
          <img *ngIf="searchService._name" (click)="searchService._name = ''" src="assets/images/main/cross-icon.svg" alt="">
        </div>
      </div>
    </ng-template>
  </p-sidebar>
</div>

<app-auth-dialog [url]="redirectUrl" (successAuth)="getInfo()"></app-auth-dialog>

<p-toast class="main-toast" position="bottom-center">
  <ng-template let-message pTemplate="message">
    <div>
      <div class="text-left flex align-items-center">
        <i class="pi pi-info-circle" style="font-size: 20px"></i>
        <p class="message">{{message.detail}}</p>
      </div>
    </div>
  </ng-template>
</p-toast>

