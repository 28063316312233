import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse, HttpClient
} from "@angular/common/http";
import {Inject, Injectable, Optional} from "@angular/core";
import {Observable, switchMap, throwError} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {REQUEST} from "@nguniversal/express-engine/tokens";
import { Request } from 'express'
import {CookieService} from "ngx-cookie-service";
import {WebViewCommunicationService} from "../webview/web-view-communication.service";


const startsWithAny = (arr: string[] = []) => (value = '') => {
  return arr.some(test => value.toLowerCase().startsWith(test.toLowerCase()));
};

const isAbsoluteURL = startsWithAny(['http', '//']);

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(private webViewService: WebViewCommunicationService,
              @Optional() @Inject(REQUEST) protected request: Request,
              private cookieService: CookieService,
              private http: HttpClient) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.cookieService.check("GATEWAY")) {
      // Если токен доступен, добавляем его в заголовки запроса
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.cookieService.get("GATEWAY")}`
        },
      });
    }

    let rq = request;
    if (this.request && !isAbsoluteURL(request.url)) {
      const protocolHost = `${this.request.protocol}://cards-front-test`;
      const pathSeparator = !request.url.startsWith('/') ? '/' : '';
      let url = protocolHost + pathSeparator + request.url;

      if(url.indexOf('cards-base.marketplace') != -1) {
        url = url.replace(`${this.request.protocol}://cards-front-test/proxy/cards-base.marketplace`, 'http://cards-base-test')
      }
      if(url.indexOf('profile-api.bpmn') != -1) {
        url = url.replace(`${this.request.protocol}://cards-front-test/proxy/profile-api.bpmn`, 'http://profile-api-test')
      }

      rq = request.clone({ url });
    }

    return next.handle(rq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Обновляем токен
          return this.http.post('/proxy/account-recovery-api.bpmn/auth/login/refresh/token', {}).pipe(
            // После обновления токена, повторяем запрос
            switchMap(() => {
              return next.handle(request.clone({
                setHeaders: {
                  Authorization: `Bearer ${this.cookieService.get('GATEWAY')}`
                }
              }));
            }),
            catchError(() => {
              this.webViewService.sendAction('LOGOUT');
              return throwError('Пользователь не авторизован');
            })
          );
        }
        return throwError(error);
      })
    );
  }
}
