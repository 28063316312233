import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StatGovCompanyInfo} from "src/user-info";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MainService {

  mainApi = '/proxy/cards-base.marketplace/api/';

  constructor(protected http: HttpClient) {}

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      throw error;
    };
  }

  checkPulse(){
    return this.http.get('/bpmn/api/v2/public/regular-banner?name=MARKET_OFF');
  }

  getAllCardsWithParams(text = '', regionId= '', categoryId= '', categoryType = '', companyBin= '', priceFrom= '', priceTo= '', pageNumber: any = 0, pageSize: any = 16, sortByAsc: any = '', sortBy: any = ''){
    return this.http.get(this.mainApi + 'cards/public-cards-pageable?page=' + pageNumber + '&size=' + pageSize + '&text=' + text + '&regionId=' + regionId + '&categoryId=' + categoryId + '&categoryType=' + categoryType + '&companyBin=' + companyBin + '&sortByAsc=' + sortByAsc + '&sortBy=' + sortBy + '&priceFrom=' + priceFrom + '&priceTo=' + priceTo)
  }

  getFavList(pageNumber: any = 0, pageSize: any = 5){
    return this.http.get(this.mainApi + 'internal/cards/list-favourites-pageable?page=' + pageNumber + '&size=' + pageSize)
  }

  userInfo(){
    return this.http.get(this.mainApi + 'internal/companies/attach-from-ismet')
  }

  deleteCard(cardId: any) {
    return this.http.get(this.mainApi + 'internal/cards/delete-by-id?cardId=' + cardId)
  }

  getBPMNaddress(data: any){
    return this.http.post('/bpmn/api/v2/public/search/full-address', {from: '0', q: data, size: '30'})
  }


  getUA(data: any){
    return this.http.post('/bpmn/api/v2/public/search/full-address', {data})
  }

  selectCompany(company: any){
    return this.http.put('/proxy/profile-api.bpmn/api/v1/profile/make-users-main-company/' + company, {})
  }

  createOrder(value: any){
    return this.http.post(this.mainApi + 'orders/create', value)
  }

  getCardById(id: any){
    return this.http.get(this.mainApi + 'cards/by-id?cardId=' + id)
  }

  reviewSend(reviewData: any = {}){
    return this.http.post(this.mainApi + 'internal/feedback/new-feedback', reviewData)
  }

  companyAddData(bin: any){
    return this.http.get(this.mainApi + 'internal/users/profile-info?bin=' + bin)
  }

  getCardComments(id: any){
    return this.http.get(this.mainApi + 'internal/moderation-comments/list-by-card-id?cardId=' + id);
  }

  getPurchases(params: any){
    return this.http.get(this.mainApi + 'internal/orders/me-buying?page=' + params.page + '&size=' + params.size + '&searchText=' + params.searchText)
  }

  approve(id: any){
    return this.http.post(this.mainApi + 'internal/orders/complete', {order_id: id})
  }

  getSells(params: any){
    return this.http.get(this.mainApi + 'internal/orders/me-selling?page=' + params.page + '&size=' + params.size + '&searchText=' + params.searchText)
  }

  getSellsTU(params: any){
    return this.http.get(this.mainApi + 'internal/orders/me-selling?page=' + params.page + '&size=' + params.size + '&searchText=' + params.searchText + '?tu=true')
  }

  takeTUtoWork(id: any){
    return this.http.post(this.mainApi + 'companies/list', {order_id: id})
  }

  getCompaniesFilterList(){
    return this.http.get(this.mainApi + 'companies/list')
  }

  getMyCards(params: any){
    return this.http.post(this.mainApi + 'internal/cards/by-filter', params)
  }

  takeToWork(cardId: any){
    return this.http.post(this.mainApi + 'internal/orders/take-to-work', {order_id: cardId})
  }

  rejectApplication(cardId: any, comment: any){
    return this.http.post(this.mainApi + 'internal/orders/reject', {order_id: cardId, comment_on_reject: comment})
  }

  delegateApplication(cardId: any, user_id: any){
    return this.http.post(this.mainApi + 'internal/orders/delegate', {order_id: cardId, user_id: user_id})
  }

  getTUId(orderId: any){
    return this.http.get(this.mainApi + 'internal/orders/get-file-link-by-callback-order-id/' + orderId)
  }

  completeOrder(cardId: any){
    return this.http.post(this.mainApi + 'internal/orders/move-to-confirmation', {order_id: cardId})
  }

  getEmployeeList(){
    return this.http.get(this.mainApi + 'internal/users/get-user-list/by-company_bin-and-right')
  }

  addTag(id: any){
    return this.http.post(this.mainApi + 'internal/tags/activate', id);
  }

  deleteTag(id: any){
    return this.http.delete(this.mainApi + 'internal/tags/delete', id);
  }

  addNewCategory(value: any){
    return this.http.post(this.mainApi + 'internal/categories/add-category-to-directory', value);
  }

  addFeedbackToPublic(value: any){
    return this.http.post(this.mainApi + 'internal/feedback/to-public', value);
  }

  tableList(value: any){
    return this.http.post(this.mainApi + 'internal/cards/by-filter', value );
  }
  moderatorTableList(value: any){
    return this.http.post(this.mainApi + 'internal/cards/by-filter-moderator', value );
  }

  reviewList(params: any){
    return this.http.get(this.mainApi + 'internal/cards/list-to-moderation/with-feedbacks-pageable?page=' + params.page + '&size=' + params.size + '&sortBy=' + params.sortBy + '&sortByAsc=' + params.sortByAsc);
  }

  statusList(){
    return this.http.get(this.mainApi + 'enums/states-of-cards');
  }

  getCardDataForCabinet(id: any){
    return this.http.get(this.mainApi + 'internal/cards/by-id?cardId=' + id);
  }

  getLS(bin: any){
    return this.http.get(this.mainApi + 'common/get-abonent-id-list-by-uin/' + bin);
  }

  getManufacturer(categoryId: any){
    return this.http.get(this.mainApi + 'cards/by-category?categoryId=' + categoryId);
  }

  getPositiveList(cardId: any, pageNumber: any = 0, pageSize: any = 5){
    return this.http.get(this.mainApi + 'feedback/list-positive?cardId=' + cardId + '&page=' + pageNumber + '&size=' + pageSize)
  }

  getNegativeList(cardId: any, pageNumber: any = 0, pageSize: any = 5){
    return this.http.get(this.mainApi + 'feedback/list-negative?cardId=' + cardId + '&page=' + pageNumber + '&size=' + pageSize)
  }

  getFeedBackListByCardId(cardId: any, pageNumber: any = 0, pageSize: any = 5){
    return this.http.get(this.mainApi + 'feedback/public-feedbacks-pageable?cardId=' + cardId + '&page=' + pageNumber + '&size=' + pageSize)
  }

  getRegionList(){
    return this.http.get(this.mainApi + 'regions/list')
  }

  getCategoriesList(){
    return this.http.get(this.mainApi + 'categories/listByParent')
  }

  getFavoriteNumber(){
    return this.http.get(this.mainApi + 'internal/user-favourite-cards/getFavCardCount')
  }

  postFavorite(favItemId: any){
    return this.http.post(this.mainApi + 'internal/user-favourite-cards/' + favItemId, {});
  }

  deleteFavorite(favItemId: any){
    return this.http.delete(this.mainApi + 'internal/user-favourite-cards/' + favItemId);
  }

  getCatalogCategories(type: string){
    return this.http.get(this.mainApi + 'categories/by-type?type=' + type)
  }

  getCatalogCategoriesInternal(type: string){
    return this.http.get(this.mainApi + 'internal/categories/list?type=' + type)
  }

  getOriginCountry(){
    return this.http.get(this.mainApi + 'cards/countries')
  }

  getCatalogSubCategoriesInternal(type: string, parentId: any){
    return this.http.get(this.mainApi + 'internal/categories/list?type=' + type + '&parentCategoryId=' + parentId)
  }

  cardToModeration(value: any){
    return this.http.post(this.mainApi + 'internal/cards/to-moderation', value)
  }

  cardToDraft(value: any){
    return this.http.post(this.mainApi + 'internal/cards/save-as-draft', value)
  }

  getSubCategory(categoryId: any){
    return this.http.get(this.mainApi + 'categories/by-id?id=' + categoryId)
  }

  getAllCategoryTypes(){
    return this.http.get(this.mainApi + 'enums/types-of-categories')
  }

  getSelectedCategory(categoryId: any){
    return this.http.get(this.mainApi + 'categories/by-id?id=' + categoryId)
  }
  addComment(id: any){
    return this.http.post(this.mainApi + 'internal/feedback/to-public', {id: id})
  }

  cardToCorrect(value: any){
    return this.http.post(this.mainApi + 'internal/cards/' + value.id + '/correct', {comment: value.comment})
  }

  deleteComment(value: any){
    return this.http.post(this.mainApi + 'internal/feedback/moderator-delete', value)
  }

  cardToPublic(id: any){
    return this.http.post(this.mainApi + 'internal/cards/' + id + '/public', {})
  }

  removeComment(id: any){
    return this.http.post(this.mainApi + 'internal/feedback/to-public', {id: id})
  }

  getModerComments(id: any, pageSize = 10, pageNumber = 0){
    return this.http.get(this.mainApi + 'internal/feedback/feedbacks-of-card-for-moderation-pageable?cardId=' + id + '&page=' + pageNumber + '&size=' + pageSize)
  }

  getTags(){
    return this.http.get(this.mainApi + 'internal/tags/list')
  }

  getTabCount(){
    return this.http.get(this.mainApi + 'internal/cards/count-for-tabs')
  }

  getNumberOfCards(){
    return this.http.get(this.mainApi + 'cards/count-by-types')
  }

  getCompanyInfo(bin: any){
    return this.http.get(this.mainApi + 'internal/companies/' + bin)
  }

  getPublicCompanyInfo(bin: any){
    return this.http.get(this.mainApi + 'companies/by-bin?bin=' + bin)
  }

  getPublicCompanyInfoForModerator(bin: any){
    return this.http.get(this.mainApi + 'companies/by-bin/for-moderation?bin=' + bin)
  }

  fromPublicToArchive(id: any){
    return this.http.post(this.mainApi + 'internal/cards/' + id + '/archive', {})
  }

  fromArchiveToPublic(id: any){
    return this.http.post(this.mainApi + 'internal/cards/' + id + '/moderation', {})
  }

  getNameFromStatGov(bin: string): Observable<StatGovCompanyInfo> {
    return this.http.get<StatGovCompanyInfo>((`/proxy/profile-api.bpmn/api/v1/profile/profile-by-bin/${bin}`))
      .pipe(catchError(this.handleError('getNameFromStatGov', new StatGovCompanyInfo())));
  }

  bmgCompany(bin: any) : Observable<any> {
    return this.http.post((`/proxy/profile-api.bpmn/api/v1/bmg/attach`), {uin: bin})
      .pipe(catchError(this.handleError('bmgCompany', [])));
  }

  signQR(userId: any) : Observable<any> {
    return this.http.post(("/proxy/profile-api.bpmn/api/v1/qr-sign/attach"), userId)
      .pipe(catchError(this.handleError('signQR', [])));
  }

  signEcp(signedXml: any) : Observable<any> {
    return this.http.post(("/proxy/profile-api.bpmn/api/v1/eds/attach "), signedXml)
      .pipe(catchError(this.handleError('signQR', [])));
  }

  getCompanyImg(uin: string): Observable<any> {
    return this.http.get(encodeURI(`/proxy/profile-api.bpmn/api/v1/company/avatar/${uin}`),
      { responseType: 'blob' }).pipe(catchError(this.handleError('getCompanyImg', [])));
  }

  editCard(cardId: number): Observable<any> {
    return this.http.get(this.mainApi + 'internal/cards/by-id?cardId=' + cardId)
  }

  publicEditCard(data: any): Observable<any> {
    return this.http.post(this.mainApi + 'internal/cards/update', data)
  }

  draftEditCard(id: any): Observable<any> {
    return this.http.post(this.mainApi + 'internal/cards/' + id + '/draft', {})
  }

  addCompany(data: FormData) {
    return this.http.post(this.mainApi +  "internal/companies/add", data)
      .pipe(catchError(this.handleError('addCompany', [])));
  }

  editCompany(data: FormData) {
    return this.http.post(this.mainApi +  "internal/companies/to-moderation", data)
      .pipe(catchError(this.handleError('addCompany', [])));
  }

  receivingAllCompanyBusinessCards(params: any) {
    return this.http.post(this.mainApi + 'internal/companies/list-pageable', params);
  }

  publishCard(id: any) {
    return this.http.post(this.mainApi +  "internal/companies/" + id + "/to-public", {})
        .pipe(catchError(this.handleError('publishCard', [])));
  }

  declineCard(id: any, com: any) {
    return this.http.post(this.mainApi +  "internal/companies/" + id + "/reject", {comment: com})
        .pipe(catchError(this.handleError('declineCard', [])));
  }

  adjustmentCard(id: any, com: any) {
    return this.http.post(this.mainApi +  "internal/companies/" + id + "/correct", {comment: com})
        .pipe(catchError(this.handleError('adjustmentCard', [])));
  }

  addCommentModeration(id: any, com: any) {
    return this.http.post(this.mainApi +  "internal/moderation-company-comments/add", {company_id: id, comment: com})
        .pipe(catchError(this.handleError('addCommentModeration', [])));
  }

  cardAvailabilityCheck(bin: any){
    return this.http.get(this.mainApi + `cards/by-company-bin?companyBin=${bin}`);
  }
}
