import { Component, OnInit } from '@angular/core';
import {MainService} from "../../../services/main/main.service";


@Component({
  selector: 'app-tu-orders',
  templateUrl: './tu-orders.component.html',
  styleUrls: ['./tu-orders.component.sass']
})
export class TuOrdersComponent implements OnInit{

  tableParams: any = {
    page: 0,
    size: 10,
    stateList: [],
    name: '',
    bin: '',
    vendorCode: '',
    sortBy: 'id',
    sortByAsc: true,
    searchText: ''
  }

  tuList: any;

  constructor(private main: MainService) {

  }

  ngOnInit() {
    this.getTUList()
  }

  getTUList(){
    this.main.getSellsTU(this.tableParams).subscribe((res: any) => {
      this.tuList = res;
    })
  }

  takeToWork(id: any){
    this.main.takeTUtoWork(id).subscribe((res: any) => {

    })
  }

}
