import {Component, OnInit} from '@angular/core';
import {MainService} from "../../../services/main/main.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {HeaderService} from "../../../services/header/header.service";

@Component({
  selector: 'app-my-products-and-services',
  templateUrl: './my-products-and-services.component.html',
  styleUrls: ['./my-products-and-services.component.sass']
})
export class MyProductsAndServicesComponent implements OnInit {
  companyBin: any;
  tableParams: any = {
      page: 0,
      size: 10,
      name: '',
      bin: '',
      vendorCode: '',
      sortBy: 'id',
      sortByAsc: true,
      searchText: ''
  }
  loadTable = false;
  products: any = {
      content: [],
      totalElements: 0
  };
  currentProduct: any;
  pmenu: any = [];
  loadSpinner: boolean = false;
  stateModeration: any;
  excludeStatusId = [0, 1, 2, 3];
  openDeclineModal: boolean = false;
  textModeration: any;
  text: any;
  showAddBtn = true;
  emptySearchResult = false;
  deleteModal = false;

  constructor(private main: MainService, public translate: TranslateService, private route: Router,
              private headerService: HeaderService) {
  }

  ngOnInit() {
    if (this.headerService.currentCompany) {
        this.companyBin = this.headerService.currentCompany;
        this.getCompanyData();
    }
    this.headerService.companyChangeEmitter.subscribe(company => {
        this.companyBin = this.headerService.currentCompany;
        this.getCompanyData();
    });
    this.menuItems();
  }

  getExcludeStatusModeration(state: any) {
    let statusId = Number(state);
    return this.excludeStatusId.indexOf(statusId) != -1;
  }

  clear(){
    this.tableParams.searchText = '';
    this.quickSearch();
  }

  quickSearch(){
    this.getProducts();
    this.emptySearchResult = true;
  }

  menuItems(){
    this.pmenu[0] = [
      {
        label: '',
        id: 'Edit',
        command: (): any => {
          this.route.navigate(['edit-card/' + this.currentProduct.id]);
        }
      },
      {
        label: '',
        id: 'Archive',
        command: (): any => {
          this.fromPublicToArchive();
        }
      },
      {
        label: '',
        id: 'Delete',
        command: (): any => {
          this.deleteModal = true;
        }
      }
    ];
    this.pmenu[1] = [
      {
        label: '',
        id: 'Look',
        command: (): any => {
        }
      },
      {
        label: '',
        id: 'Delegate',
        command: (): any => {

        }
      },
      {
        label: '',
        id: 'CloseAsCompleted',
        command: (): any => {

        }
      },
      {
        label: '',
        id: 'MessageTpPartner',
        command: (): any => {

        }
      },
      {
        label: '',
        id: 'Delete',
        command: (): any => {
          this.deleteModal = true;
        }
      }
    ];
    this.pmenu[2] = [
      {
        label: '',
        id: 'Edit',
        command: (): any => {
          this.route.navigate(['edit-card/' + this.currentProduct.id]);
        }
      },
      {
        label: '',
        id: 'Archive',
        command: (): any => {
          this.fromPublicToArchive();
        }
      },
      {
        label: '',
        id: 'Delete',
        command: (): any => {
          this.deleteModal = true;
        }
      }
    ];
    this.pmenu[3] = [
      {
        label: '',
        id: 'Edit',
        command: (): any => {
          this.route.navigate(['edit-card/' + this.currentProduct.id]);
        }
      },
      {
        label: '',
        id: 'Archive',
        command: (): any => {
          this.fromPublicToArchive();
        }
      },
      {
        label: '',
        id: 'Delete',
        command: (): any => {
          this.deleteModal = true;
        }
      }
    ];
    this.pmenu[4] = [
      {
        label: '',
        id: 'Edit',
        command: (): any => {
          this.route.navigate(['edit-card/' + this.currentProduct.id]);
        }
      },
      {
        label: '',
        id: 'Delete',
        command: (): any => {
          this.deleteModal = true;
        }
      }
    ];
    this.pmenu[5] = [
      {
        label: '',
        id: 'Public',
        command: (): any => {
          this.archiveTopublic();
        }
      },
      {
        label: '',
        id: 'Edit',
        command: (): any => {
          this.route.navigate(['edit-card/' + this.currentProduct.id]);
        }
      },
      {
        label: '',
        id: 'Delete',
        command: (): any => {
          this.deleteModal = true;
        }
      }
    ];

  }

  deleteCard(){
    this.main.deleteCard(this.currentProduct.id)
      .subscribe({
        next: (res: any) => {
          this.deleteModal = false;
          this.quickSearch();
        },
        error: (err: any) => {
        }
      })
  }

  changeLang(product: any){
    this.pmenu.forEach((item: any) => {
      item.forEach((subItem: any) => {
        this.translate.get(''+subItem.id).subscribe((translate: any) => {
          subItem.label = translate;
        })
      });
    });
    this.currentProduct =  product;
  }

  changePage(event: any){
    this.loadTable = true;
    this.tableParams.page = event.page;
    this.tableParams.size = event.rows;
    this.getProducts();
  }

  addSpacesToNumberString(input: string): string {
    if (input.length <= 3) {
      return input; // Возвращаем строку без изменений, если длина <= 3
    }
    const reversedInput = input.split('').reverse().join(''); // Разворачиваем строку
    const spacedString = reversedInput.replace(/(\d{3})/g, '$1 ').trim(); // Добавляем пробел каждые 3 символа и удаляем лишние пробелы
    return spacedString.split('').reverse().join(''); // Возвращаем развернутую строку обратно
  }

  getProducts() {
    this.tableParams.bin = this.companyBin;
    this.main.getMyCards(this.tableParams).subscribe({
      next: (res: any) => {
        this.products = res;
        this.loadTable = false;
      },
      complete: () => {
      },
      error: (err: any) => {
      }
    });
  }

  fromPublicToArchive() {
    this.main.fromPublicToArchive(this.currentProduct.id).subscribe({
      next: (res: any) => {
        this.getProducts();
      },
      complete: () => {
      },
      error: (err: any) => {
      }
    });
  }

  archiveTopublic() {
    this.main.fromArchiveToPublic(this.currentProduct.id).subscribe({
      next: (res: any) => {
        this.getProducts();
      },
      complete: () => {},
      error: (err: any) => {}
    });
  }

  getCompanyData() {
    this.loadSpinner = true;
    this.stateModeration = undefined;
    this.main.getCompanyInfo(this.companyBin).subscribe({
      next: (res: any) => {
        this.stateModeration = res.state.id;
        this.text = res.moderator_visit_comment;
        this.getProducts();
        this.loadSpinner = false;
      },
      complete: () => {
      },
      error: (err: any) => {
        this.loadSpinner = false;
      }
    });
  }

  declineModal() {
    this.textModeration = this.translate.instant('moderatorsComment:') + ' ' + this.text;
    this.openDeclineModal = true;
  }
}
