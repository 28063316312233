import {EventEmitter, Injectable} from '@angular/core';
import { Subject } from 'rxjs';
import {MainService} from "../main/main.service";

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  // @ts-ignore
  public eventEmitter: EventEmitter<any> = new EventEmitter({ level: 0, item: {}, index: 0 });

  changeCatalogItem(level: any, item: any, index: any) {
    this.eventEmitter.emit({level, item, index});
  }

  public _currentCompany: any;

  public _userData: any;

  public _parentIndex: any = 0;

  public _typeIndex: any = 0;

  public _catalogPage = 0;

  public _catalog: any = [
    {
      name_ru: 'Товары',
      name_kz: 'Тауарлар',
      type: 'GOOD',
      active: false,
      parentlist: []
    },
    {
      name_ru: 'Услуги',
      name_kz: 'Қызметтер',
      type: 'SERVICE',
      parentlist: []
    }
  ];

  public goodName = '';

  public favNumber = 0;

  constructor(private main: MainService) { }

  get mainCatalog(): any {
    return this._catalog;
  }

  set mainCatalog(value: any){
    this._catalog = value;
  }

  get currentCompany(): any {
    return this._currentCompany;
  }

  get userData(): any {
    return this._userData;
  }

  set currentCompany(value: any) {
    this._currentCompany = value;
    this.companyChangeEmitter.emit(this._currentCompany)
  }

  set userData(value: any) {
    this._userData = value;
  }

  favoriteCounter(){
    this.main.getFavoriteNumber().subscribe((res: any) => {
      this.favNumber = res;
    })
  }

  checkFavItem(arr: any){
    arr.forEach((element: any) => {
      // @ts-ignore
      if(element.id == +localStorage.getItem('favId')){
        element.favorite = !element.favorite
      }
    })
  }

  private languageChangeSubject = new Subject<string>();
  public companyChangeEmitter: EventEmitter<any> = new EventEmitter();
  public openAuthEmitter: EventEmitter<any> = new EventEmitter();

  languageChange$ = this.languageChangeSubject.asObservable();

  changeLanguage(language: string) {
    this.languageChangeSubject.next(language);
  }

  openAuthDialog() {
    this.openAuthEmitter.emit()
  }
}
