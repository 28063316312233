import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {HeaderService} from "../../services/header/header.service";
import {MainService} from "../../services/main/main.service";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.sass']
})
export class AccountComponent implements OnInit {
  activeIndex: number = 0;
  companyBin: any;
  stateModeration: any;

  constructor(private route: Router, public headerService: HeaderService, private main: MainService) {
  }

  ngOnInit() {
    this.selectActiveTab();
    if (this.headerService.currentCompany) {
      this.companyBin = this.headerService.currentCompany;
      this.getCompanyData();
    }
    this.headerService.companyChangeEmitter.subscribe(company => {
      this.companyBin = this.headerService.currentCompany;
      this.getCompanyData();
    });



  }

  getCompanyData() {
    this.main.getCompanyInfo(this.companyBin).subscribe({
      next: (res: any) => {
        this.stateModeration = res.state.id;
      },
      complete: () => {
      }
    });
  }

  changeRoute(event: any) {
    switch (event.index) {
      case 0:
        this.route.navigate(['account/tu-orders']);
        break;
      case 1:
        this.route.navigate(['account/sales']);
        break;
      case 2:
        this.route.navigate(['account/purchases']);
        break;
      case 3:
        this.route.navigate(['account/my-products-and-services']);
        break;
      case 4:
        this.route.navigate(['account/edit-company']);
        break;
    }
  }

  selectActiveTab() {
    switch (this.route.url) {
      case '/account/sales':
        this.activeIndex = 0;
        break;
      case '/account/purchases':
        this.activeIndex = 1;
        break;
      case '/account/my-products-and-services':
        this.activeIndex = 2;
        break;
      case '/account/edit-company':
        this.activeIndex = 3;
        break;
    }
  }
}
