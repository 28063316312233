import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SignControllerService} from "../../../services/sign/sign-controller.service";
import {IinOrBinCheckResult, OcpUtils} from "../../../../OcpUtils";

@Component({
  selector: 'app-eds',
  templateUrl: './eds.component.html',
  styleUrls: ['./eds.component.sass']
})
export class EdsComponent {
  @Output() callback = new EventEmitter<any>();
  @Output() cmsCallback = new EventEmitter<any>();
  @Input() dataForSign!: any;
  @Input() bin!: string | undefined;
  @Input() openAddCompany!: boolean;
  @Input() format!: string;
  SOCKET_URL = 'wss://127.0.0.1:13579/';
  webSocketInstance: any;
  notStarted: boolean = false;
  opened: boolean = false;
  loader: boolean = false;
  versionShowed: boolean = false;
  locale: string = document.documentElement.lang
  spinner: boolean = false;
  errorCompanyInfo: boolean = false;

  constructor(private signControllerService: SignControllerService) {}

  connect(): Promise<WebSocket> {
    if (this.webSocketInstance && this.webSocketInstance.readyState < 2) {
      return Promise.resolve(this.webSocketInstance);
    }
    return new Promise<WebSocket>((resolve, reject) => {
      this.webSocketInstance = new WebSocket(this.SOCKET_URL);
      this.webSocketInstance.onopen = () => {
        resolve(this.webSocketInstance);
      };
      this.webSocketInstance.onerror = (err: any) => {
        reject(err);
      };
    });
  }

  signXml() {
    this.errorCompanyInfo = false;
    let bin: string | undefined = '';
    let iin: string | undefined = '';
    let res = OcpUtils.isIinOrBin(this.bin);
    if(res == IinOrBinCheckResult.BIN) {
      bin = this.bin
    }
    if(res == IinOrBinCheckResult.IIN) {
      iin = this.bin
    }

    const signInfo = {
      module: 'kz.gov.pki.knca.basics',
      method: 'sign',
      args: {
        allowedStorages: ['PKCS12'],
        format: this.format ? this.format : 'xml',
        data: this.dataForSign,
        signingParams: {decode: 'true', encapsulate: 'true', digested: 'false', tsaProfile: {}},
        signerParams: {
          extKeyUsageOids: [],
          chain: [],
          iin: iin,
          bin: bin
        },
        locale: 'ru'
      }
    };

    this.connect().then(webSocket => {
      this.notStarted = false;
      webSocket.send(JSON.stringify(signInfo));
      if(this.versionShowed) {
        this.opened = true;
      } else {
        this.loader = true;
      }
      webSocket.onmessage = ({data}) => {
        let response = JSON.parse(data);
        if (response != null) {
          const responseStatus = response['status'];
          if (responseStatus === true) {
            const responseBody = response['body'];
            if (responseBody != null) {
              if (responseBody.hasOwnProperty('result')) {
                if(this.openAddCompany) {
                  this.attachVerifyCompany(responseBody.result[0])
                } else if(this.format == 'cms') {
                  this.cmsCallback.emit(responseBody.result);
                } else {
                  this.callback.emit(responseBody.result);
                }
                this.closeWebSocket()
              }
            }
            this.opened = false;
          }
          const responseVersion = response['result']?.['version'];
          if(responseVersion) {
            this.versionShowed = true;
            this.loader = false;
            this.opened = true;
          }
        }
      };
    }).catch(() => {
      this.notStarted = true;
    });
  }

  attachVerifyCompany(signedXml: string) {
    this.spinner = true;
    if (this.bin) {
      this.signControllerService.verifyEds(signedXml, this.bin).subscribe(() => {
        this.callback.emit(this.bin);
      }, () => {
        this.errorCompanyInfo = true;
      }).add(()=> this.spinner = false);
    } else {
      this.signControllerService.attachEds(signedXml).subscribe((data) => {
        this.callback.emit(data.uin);
      }, () => {
        this.errorCompanyInfo = true;
      }).add(()=> this.spinner = false);
    }
    this.closeWebSocket()
  }

  closeWebSocket() {
    if(this.webSocketInstance) {
      this.webSocketInstance.close();
    }
  }
}
