import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {AuthService} from "../../../services/auth/auth.service";
import {CookieService} from 'ngx-cookie-service';
import {TranslateService} from '@ngx-translate/core';
import {MainService} from "../../../services/main/main.service";
import {MessageService, PrimeNGConfig} from "primeng/api";
import {HeaderService} from "../../../services/header/header.service";
import {ActivatedRoute, Router} from '@angular/router';
import {SearchService} from "../../../services/search/search.service";
import {WebViewCommunicationService} from "../../../services/webview/web-view-communication.service";
import {AuthDialogComponent} from "../../auth-dialog/auth-dialog.component";
import { Location } from '@angular/common';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  logoutLink = `/auth/logout?redirectUrl=${window.location.origin}`;
  isAuth: any = false;
  userData: any = {
    userInfo:{
      isModerator: false
    },
    companyList: []
  };
  loadSpinner: boolean = false;
  openSearch = false;
  redirectUrl = '';
  activeIndex:any = 1;
  catalogArray: any = [
    {
      name_ru: 'Товары',
      name_kz: 'Тауарлар',
      type: 'GOOD',
      active: true,
      parentlist: []
    },
    {
      name_ru: 'Услуги',
      name_kz: 'Қызметтер',
      type: 'SERVICE',
      parentlist: []
    }
  ];

  private timeoutId: any = null;

  @Input() notAvailable: any;

  @ViewChild(AuthDialogComponent) authDialog!: AuthDialogComponent;

  constructor(private config: PrimeNGConfig, private authService: AuthService,
              private message: MessageService,
              public location: Location,
              public webViewService: WebViewCommunicationService,
              private route: ActivatedRoute, private router: Router,
              public translate: TranslateService, private main: MainService,
              public searchService: SearchService,
              public headerService: HeaderService) {
    this.authService.auth.subscribe(() => this.getInfo());
    this.headerService.openAuthEmitter.subscribe(() => this.authDialog.openDialog());
    this.getInfo();
  }

  getInfo() {
    this.authService.isLoggedIn().then(auth => {
      this.isAuth = auth;
      if (auth) {
        this.getUserInfo();
      }
    });
  }

  search(){
    this.searchService._pageNumber = 0;
    this.searchService.search();
  }

  goToSearch(item: any, index: any, level: any){
    this.headerService._catalog[this.headerService._typeIndex].parentlist[this.headerService._parentIndex]?.child_categories.forEach((element: any) => {
      element.active = false;
    });
    item.active = true;
    if(level == 2){
      this.headerService._catalogPage = 1;
    }else if(level == 3){
      this.headerService._catalogPage = 2;
    }
    this.headerService.changeCatalogItem(level, item, index);
    this.searchService.categoryId = item.id;
    this.triggerCatalog();
  }

  ngOnInit() {
    setTimeout(() => {
      this.route.queryParams.subscribe((params: any) => {
        if(params?.auth){
          this.redirectUrl = params?.redirectUrl;
          this.authDialog.openDialog();
        }
        if (params?.lang == 'ru') {
          this.translate.setDefaultLang('ru');
          this.translate.use('ru');
          if (typeof window !== 'undefined') {
            localStorage.setItem('lang', 'ru');
          }
        } else if (params?.lang == 'kk') {
          this.translate.use('kz');
          if (typeof window !== 'undefined') {
            localStorage.setItem('lang', 'kz');
          }
        } else if (typeof window !== 'undefined') {
          if (localStorage.getItem('lang') == null || localStorage.getItem('lang') == 'ru') {
            this.translate.setDefaultLang('ru');
            this.translate.use('ru');
            localStorage.setItem('lang', 'ru');
          } else {
            this.translate.use('kz');
            localStorage.setItem('lang', 'kz');
          }
        }
      });
    }, 1)
    this.getCategories();
    this.setFavItem();
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  setFavItem(){
    if(localStorage.getItem('favId')){
      this.setFavorite(localStorage.getItem('favId'));
    }
  }

  setFavorite(itemId: any) {
    this.main.postFavorite(itemId).subscribe({
      next: () => {
        localStorage.removeItem('favId')
        // @ts-ignore
        window['yaCounter48820577'].reachGoal('obshee-kolichestvo-dobavleniy-tovarov-v-izbrannoe');
      },
      complete: () => {
        this.headerService.favoriteCounter();
      },
      error: () => {
      }
    });
  }

  triggerCatalog(){
    this.activeIndex = this.activeIndex === 1 ? 0 : 1;
    if(this.activeIndex == 0){
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'scroll';
    }else{
      document.body.style.position = '';
    }
  }

  goToFavorite(){
    if (this.isAuth){
      this.router.navigate(['/favorite']);
    }else{
      this.redirectUrl = '/favorite';
      this.authDialog.openDialog();
    }

  }

  getCategories(){
    this.main.getCategoriesList().subscribe({
      next: (res: any) => {
        res.forEach((element: any) => {
          // res[0].active = true;
          if(this.catalogArray[0].type == element.type){
            this.catalogArray[0]?.parentlist.push(element)
          }else{
            this.catalogArray[1]?.parentlist.push(element)
          }

        });
        this.headerService.mainCatalog = this.catalogArray;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  setActiveItem(array: any, index: any, column: any){
    this.clearTimeout();
    this.timeoutId = setTimeout(() => {
      if(column == 1){
        this.headerService._typeIndex = index;
        this.headerService._parentIndex = 0;
        if(index != 0){
          this.headerService._catalog[1].parentlist.forEach((element: any) => {
            element.active = false;
          });
          this.headerService._catalog[1].parentlist[0].active = true;
        }else{
          this.headerService._catalog[0].parentlist.forEach((element: any) => {
            element.active = false;
          });
          this.headerService._catalog[0].parentlist[0].active = true;
        }

      }
      if(column == 2){
        this.headerService._parentIndex = index;
      }
      array.forEach((element: any) => {
        element.active = false;
      });
      array[index].active = true;
      }, 200
    );

  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  selectCompany(company: any, index: any) {
    this.main.selectCompany(company.uin).subscribe({
      next: () => {
        this.userData.companyList.forEach((element: any) => {
          element.mainCompany = false;
        });
        this.userData.companyList[index].mainCompany = true;
      },
      complete: () => {
      },
      error: (err: any) => {
        this.message.add({
          severity: 'error',
          summary: 'Error',
          detail: this.translate.currentLang == 'ru' ? 'Произошла ошибка при смене компании, повторите позднее' : 'Компанияны өзгерту кезінде қате орын алды, әрекетті кейінірек қайталаңыз',
          life: 4000
        });
      }
    });
    this.headerService.currentCompany = company.uin;
  }

  getUserInfo() {
    this.loadSpinner = true;
    this.main.userInfo().subscribe({
      next: (res: any) => {
        this.userData = res;
        this.headerService.userData = res;
        this.headerService.favoriteCounter();
        if (res.userInfo.mainCompanyBin != null) {
          localStorage.setItem('company', JSON.stringify(res.userInfo));
          this.headerService.currentCompany = res.userInfo.mainCompanyBin;
        } else {
          localStorage.setItem('company', '0');
        }
        this.loadSpinner = false;
      },
      complete: () => {
      },
      error: () => {
        this.loadSpinner = false;
      }
    });
  }

  login() {
    this.authDialog.openDialog();
  }

  setLang(lang: any) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    this.headerService.changeLanguage(lang);
    window.location.reload();
  }

  lang() {
    if (this.translate.currentLang == 'ru') {
      return 'ru';
    } else {
      return 'kz';
    }
  }

  isMobileApp() {
    return typeof window !== 'undefined' && navigator.userAgent.includes('ismet-mobile')
  }

  protected readonly window = window;
}
