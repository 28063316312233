<p-progressSpinner *ngIf="loadSpinner" [class]="'load-spinner-global'"></p-progressSpinner>
<ng-container *ngIf="!loadSpinner">
  <div *ngIf="getExcludeStatusModeration(stateModeration) || !companyBin" class="desktop-hidden text-center mt-8 mx-6">
    <div class="mb-2">
      {{ "NotAttachCompany" | translate }}
    </div>
    <div class="">
      {{ "NotAttachCompany2" | translate }}
    </div>
  </div>
  <div *ngIf="getExcludeStatusModeration(stateModeration) || !companyBin" class="bg-white p-3 mobile-hidden">
    <div class="gold-banner flex align-items-center">
      <img src="/assets/images/banner/crown.png" alt="">
      <div class="mr-4">{{'Welcome-txt-1' | translate}}</div>
      <div>{{'Welcome-txt-2' | translate}}</div>
    </div>
    <div class="desc-wrap mt-5">
      <div class="flex">
        <div class="w-10">
          <div class="desc-head">{{ 'AttachACompany' | translate }}</div>
          <div class="desc-txt">{{ 'AttachACompany-txt-1' | translate }}</div>
        </div>
        <div class="w-2 btn-group">
          <button *ngIf="!companyBin" [routerLink]="['/attach-company']">{{ 'Attach' | translate }}</button>
          <button *ngIf="companyBin" class="ready-btn">{{ 'Ready' | translate }}</button>
        </div>
      </div>
      <p-divider></p-divider>
    </div>
    <div class="desc-wrap">
      <div class="flex">
        <div class="w-9">
          <div class="desc-head">
            {{ 'FillOutTheCompanyBusinessCard' | translate }}
          </div>
          <div class="desc-txt">
            {{ 'FillOutTheCompanyBusinessCard-txt' | translate }}
          </div>
        </div>
        <div class="w-3 btn-group gap-4">
          <button *ngIf="stateModeration == 0" [disabled]="!companyBin" [routerLink]="['/company-info']">{{'Fill' | translate }}</button>
          <div *ngIf="stateModeration == 1" class="btn-group-moderation">{{'Moderation' | translate }}
            <div class="btn-group-moderation__status-icon" pTooltip="{{'theCompany\'sBusinessCardHasBeenSentForModerationAndWillBePublishedAfterVerification' | translate}}" tooltipPosition="bottom"></div>
          </div>
          <div *ngIf="stateModeration == 3" class="btn-group-edit" [routerLink]="['/edit-company']">{{'editYourCompanyBusinessCard' | translate }}
            <div class="btn-group-edit__status-icon" pTooltip="{{'correctTheBusinessCardAccordingToTheModerator\'sComment' | translate}}" tooltipPosition="bottom"></div>
          </div>
          <div *ngIf="stateModeration == 2" class="btn-group-declined" (click)="declineModal()">{{'applicationRejected' | translate }}
            <div class="btn-group-declined__status-icon"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="mobile-wrap" *ngIf="!(getExcludeStatusModeration(stateModeration) || !companyBin)">
  <div class="desktop-hidden mx-3 bg-white pt-3" >
    <div class="block-no-content mb-3" *ngIf="products.content.length == 0 && !emptySearchResult">
      <div inlineSVG="assets/images/main/no-content.svg"></div>
      <div class="text-no-content">{{'youHaveNoProductsOrServices' | translate}}</div>
    </div>
    <div class="flex mb-3 gap-2 w-full">
      <div *ngIf="emptySearchResult || products.content.length != 0" class="w-full">
        <span class="p-input-icon-left relative w-full">
          <i class="pi pi-search"></i>
          <input
            (focusin)="showAddBtn = false"
            (focusout)="showAddBtn = true"
            [placeholder]="'Search' | translate"
            class="main-search w-full"
            type="search"
            pInputText
            (keydown.enter)="quickSearch()"
            [(ngModel)]="tableParams.searchText" />
          <img *ngIf="tableParams?.searchText != ''" (click)="clear()" src="assets/images/main/cross-icon.svg" class="close-icon" alt="">
        </span>
      </div>
      <button *ngIf="emptySearchResult || showAddBtn" [routerLink]="'/create-card'" class="add-card top-bar w-full flex justify-content-center">
        <i class="pi pi-plus sell-icon"></i>
        {{ products.content.length > 0 || emptySearchResult ? ('AddCard' | translate).slice(0, 8) : 'AddCard' | translate }}
      </button>
    </div>
    <div *ngIf="products.content.length == 0 && emptySearchResult" class="empty-search desktop-hidden">
      <img src="assets/images/errors/mobile-empty-request.svg" alt="">
      <div class="mb-3 empty-search-head mx-auto">
        {{ 'EmptyRequest' | translate }}
      </div>
      <div class="empty-search-desc mx-auto">
        {{ 'PleaseTryAgain' | translate }}
      </div>
    </div>
    <div class="good-item" *ngFor="let item of products.content">
      <div class="good-head">
        {{ 'Код' | translate }} {{item?.id}}
        <div (click)="menu.toggle($event)" class="dropdown-menu">
          <svg class="cursor-pointer"  width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6654 4.66667C11.6654 5.58714 10.9192 6.33333 9.9987 6.33333C9.07822 6.33333 8.33203 5.58714 8.33203 4.66667C8.33203 3.74619 9.07822 3 9.9987 3C10.9192 3 11.6654 3.74619 11.6654 4.66667ZM11.6654 10.5C11.6654 11.4205 10.9192 12.1667 9.9987 12.1667C9.07822 12.1667 8.33203 11.4205 8.33203 10.5C8.33203 9.57952 9.07822 8.83333 9.9987 8.83333C10.9192 8.83333 11.6654 9.57952 11.6654 10.5ZM9.9987 18C10.9192 18 11.6654 17.2538 11.6654 16.3333C11.6654 15.4129 10.9192 14.6667 9.9987 14.6667C9.07822 14.6667 8.33203 15.4129 8.33203 16.3333C8.33203 17.2538 9.07822 18 9.9987 18Z" fill="#637381"/>
          </svg>
          <p-menu (onShow)="changeLang(item)" class="table-menu" #menu [model]="pmenu[item?.state?.id]" [popup]="true"></p-menu>
        </div>
      </div>
      <div class="good-body flex">
        <div class="img-wrap">
          <img class="good-photo" [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=' + item?.images[0]?.full_path" alt="">
        </div>
        <div class="content-wrap">
          <div class="mt-2 font-medium">
            {{ translate.currentLang == 'ru' ? item?.name_ru : item?.name_kz }}
          </div>
          <div class="flex mt-2 font-medium">
            <div class="mr-auto font-bold">{{ 'Price' | translate }}</div> {{ addSpacesToNumberString(''+item?.price) }} ₸
          </div>
          <div class="mt-2">
            <div class="state" [ngClass]="{'green': item?.state?.color_id == 0, 'yellow': item?.state?.color_id == 1, 'blue': item?.state?.color_id == 4, 'red': item?.state?.color_id == 2, 'gray': item?.state?.color_id == 5}">
              {{ translate.currentLang == 'ru' ? item.state.name_ru : item.state.name_kz }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-paginator *ngIf="products.content.length != 0" (onPageChange)="changePage($event)" class="desktop-hidden" [first]="0" [rows]="10" [totalRecords]="products?.totalElements"></p-paginator>
</div>


  <div *ngIf="stateModeration == 4 || stateModeration == 5" class="main-table bg-white p-3 mobile-hidden">
    <div class="head-search-bar flex justify-content-between bg-white p-3">
      <div>
        <span class="p-input-icon-left relative" *ngIf="emptySearchResult || products.content.length != 0">
          <i class="pi pi-search"></i>
          <input [placeholder]="'Search' | translate" class="main-search" type="text" pInputText [(ngModel)]="tableParams.searchText" />
          <img *ngIf="tableParams?.searchText != ''" (click)="clear()" src="assets/images/main/cross-icon.svg" class="close-icon" alt="">
          <span class="search-txt" (click)="quickSearch()">{{ 'Find' | translate}}</span>
        </span>
      </div>
      <button *ngIf="products.content.length != 0" [routerLink]="'/create-card'" class="add-card top-bar">
        <i class="pi pi-plus sell-icon"></i>
        {{ 'AddCard' | translate }}
      </button>
    </div>
    <ng-container *ngIf="products.content.length > 0">
      <p-table [loading]="loadTable" [first]="0" [rows]="10" [value]="products.content" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th>{{'Код' | translate}}</th>
            <th>{{'Фото' | translate}}</th>
            <th>{{ "Name" | translate }}</th>
            <th>{{ "Price" | translate }}</th>
            <th>{{ "Status" | translate }}</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-i="rowIndex">
          <tr>
            <td>{{ product?.id }}</td>
            <td class="py-0">
              <img class="table-photo" [src]="'/proxy/cards-base.marketplace/api/files/by-fullpath?fullPath=' + product?.images[0]?.full_path">
            </td>
            <td>{{ translate.currentLang == 'ru' ? product?.name_ru : product?.name_kz }}</td>
            <td>{{ addSpacesToNumberString(''+product?.price) }} ₸</td>
            <td>
              <div class="state" [ngClass]="{'green': product?.state?.color_id == 0, 'yellow': product?.state?.color_id == 1, 'blue': product?.state?.color_id == 4, 'red': product?.state?.color_id == 2, 'gray': product?.state?.color_id == 5}">
                {{ translate.currentLang == 'ru' ? product.state.name_ru : product.state.name_kz }}
              </div>
            </td>
            <td style="position:relative;">
              <div (click)="menu.toggle($event)" class="dropdown-menu">
                <svg class="cursor-pointer"  width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6654 4.66667C11.6654 5.58714 10.9192 6.33333 9.9987 6.33333C9.07822 6.33333 8.33203 5.58714 8.33203 4.66667C8.33203 3.74619 9.07822 3 9.9987 3C10.9192 3 11.6654 3.74619 11.6654 4.66667ZM11.6654 10.5C11.6654 11.4205 10.9192 12.1667 9.9987 12.1667C9.07822 12.1667 8.33203 11.4205 8.33203 10.5C8.33203 9.57952 9.07822 8.83333 9.9987 8.83333C10.9192 8.83333 11.6654 9.57952 11.6654 10.5ZM9.9987 18C10.9192 18 11.6654 17.2538 11.6654 16.3333C11.6654 15.4129 10.9192 14.6667 9.9987 14.6667C9.07822 14.6667 8.33203 15.4129 8.33203 16.3333C8.33203 17.2538 9.07822 18 9.9987 18Z" fill="#637381"/>
                </svg>
                <p-menu appendTo (onShow)="changeLang(product)" class="table-menu" #menu [model]="pmenu[product?.state?.id]" [popup]="true"></p-menu>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="table relative">
        <p-paginator [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} из {totalRecords}" (onPageChange)="changePage($event)" [first]="0" [rows]="10" [rowsPerPageOptions]="[10, 20, 30]" [totalRecords]="products?.totalElements"></p-paginator>
        <div class="show-options">{{"Show" | translate}}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="products.content.length == 0">
      <div class="block-no-content">
        <div inlineSVG="assets/images/main/no-content.svg"></div>
        <div class="text-no-content">{{'youHaveNoProductsOrServices' | translate}}</div>
        <button [routerLink]="'/create-card'" class="add-card">
          <i class="pi pi-plus sell-icon"></i>
          {{ 'AddCard' | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>

<p-dialog [(visible)]="openDeclineModal" [modal]="true" [draggable]="false" [resizable]="false" styleClass="sending-moderation">
  <div class="info-block">
    <div class="info-text">{{'RejectReason1' | translate}}</div>
    <textarea readonly [ngModel]="textModeration" class="info-description"></textarea>
    <button [routerLink]="['/company-info']" class="accept-btn w-full justify-content-center" pButton type="button">
      {{'submitANewApplication' | translate }}
    </button>
    <button (click)="openDeclineModal = false" class="accept-btn w-full justify-content-center white-button" pButton type="button">
      {{'Cancel' | translate }}
    </button>
  </div>
</p-dialog>

<p-dialog [(visible)]="deleteModal" [modal]="true" [draggable]="false" [resizable]="false" styleClass="sending-moderation">
  <div class="info-block">
    <div class="info-text">{{'SureDelete' | translate}}</div>
    <button (click)="deleteCard()" class="accept-btn w-full justify-content-center" pButton type="button">
      {{'Yes' | translate }}
    </button>
    <button (click)="deleteModal = false" class="accept-btn w-full justify-content-center white-button" pButton type="button">
      {{'No' | translate }}
    </button>
  </div>
</p-dialog>
