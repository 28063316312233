<div class="flex align-items-center my-4">
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7359_6097)">
      <path d="M19 11.4548H7.82998L12.71 6.5748C13.1 6.1848 13.1 5.5448 12.71 5.1548C12.32 4.7648 11.69 4.7648 11.3 5.1548L4.70998 11.7448C4.31998 12.1348 4.31998 12.7648 4.70998 13.1548L11.3 19.7448C11.69 20.1348 12.32 20.1348 12.71 19.7448C13.1 19.3548 13.1 18.7248 12.71 18.3348L7.82998 13.4548H19C19.55 13.4548 20 13.0048 20 12.4548C20 11.9048 19.55 11.4548 19 11.4548Z" fill="#363636"/>
    </g>
    <defs>
      <clipPath id="clip0_7359_6097">
        <rect width="24" height="24" fill="white" transform="translate(0 0.455078)"/>
      </clipPath>
    </defs>
  </svg>

  <div class="ml-2">
    Заявка № 123
  </div>
</div>
<div class="">
  <div class="flex">
    <div class="order-status mr-4">
      <div class="status-head">
        Статус заявки
      </div>
      <div class="tu-status">
        <p-steps [activeIndex]="activeIndex" [model]="statusSteps" [readonly]="true"></p-steps>
      </div>
    </div>
    <div class="w-full">
      <div class="tu-panel">
        <p-panel [toggleable]="true" [collapsed]="true"
                 [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
          <ng-template pTemplate="header">
            <div class="">
              Заявка № 123
            </div>
            <div class="ml-auto">
              <svg class="minus" width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg class="plus" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.45508V17.4551" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 9.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </ng-template>
          <div class="flex">
            <div class="blue-btn mr-3">
              Принять в работу
            </div>
            <div class="white-btn" (click)="rejectApplication = true">
              Отклонить
            </div>
          </div>
          <div class="red-info-block mt-3">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.16675 4.28874H9.83342V5.9554H8.16675V4.28874ZM9.00008 12.6221C9.45842 12.6221 9.83342 12.2471 9.83342 11.7887V8.4554C9.83342 7.99707 9.45842 7.62207 9.00008 7.62207C8.54175 7.62207 8.16675 7.99707 8.16675 8.4554V11.7887C8.16675 12.2471 8.54175 12.6221 9.00008 12.6221ZM9.00008 0.12207C4.40008 0.12207 0.666748 3.8554 0.666748 8.4554C0.666748 13.0554 4.40008 16.7887 9.00008 16.7887C13.6001 16.7887 17.3334 13.0554 17.3334 8.4554C17.3334 3.8554 13.6001 0.12207 9.00008 0.12207ZM9.00008 15.1221C5.32508 15.1221 2.33341 12.1304 2.33341 8.4554C2.33341 4.7804 5.32508 1.78874 9.00008 1.78874C12.6751 1.78874 15.6667 4.7804 15.6667 8.4554C15.6667 12.1304 12.6751 15.1221 9.00008 15.1221Z" fill="#D11635"/>
            </svg>

            <div class="">
              Вы отклонили эту заявку
            </div>
          </div>
        </p-panel>
        <div class="mb-4"></div>
        <p-panel [toggleable]="true" [collapsed]="true"
                 [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
          <ng-template pTemplate="header">
            <div class="">
              Контакты
            </div>
            <div class="ml-auto">
              <svg class="minus" width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg class="plus" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.45508V17.4551" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 9.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </ng-template>
          <div class="">
            <div class="copy-board" (click)="copy('Алматы', 0)">
              <div class="copy-board-head">
                Регион
              </div>
              <div class="">
                Алматы
              </div>
              <svg *ngIf="showSvg[0]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 3.5H14.6C16.8402 3.5 17.9603 3.5 18.816 3.93597C19.5686 4.31947 20.1805 4.93139 20.564 5.68404C21 6.53969 21 7.65979 21 9.9V17M6.2 21.5H14.3C15.4201 21.5 15.9802 21.5 16.408 21.282C16.7843 21.0903 17.0903 20.7843 17.282 20.408C17.5 19.9802 17.5 19.4201 17.5 18.3V10.2C17.5 9.07989 17.5 8.51984 17.282 8.09202C17.0903 7.71569 16.7843 7.40973 16.408 7.21799C15.9802 7 15.4201 7 14.3 7H6.2C5.0799 7 4.51984 7 4.09202 7.21799C3.71569 7.40973 3.40973 7.71569 3.21799 8.09202C3 8.51984 3 9.07989 3 10.2V18.3C3 19.4201 3 19.9802 3.21799 20.408C3.40973 20.7843 3.71569 21.0903 4.09202 21.282C4.51984 21.5 5.0799 21.5 6.2 21.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg *ngIf="!showSvg[0]" class="green-mark" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_7359_55687)">
                  <path d="M7.49987 14.0195L4.6082 11.1279C4.2832 10.8029 3.7582 10.8029 3.4332 11.1279C3.1082 11.4529 3.1082 11.9779 3.4332 12.3029L6.91654 15.7862C7.24154 16.1112 7.76654 16.1112 8.09154 15.7862L16.9082 6.96953C17.2332 6.64453 17.2332 6.11953 16.9082 5.79453C16.5832 5.46953 16.0582 5.46953 15.7332 5.79453L7.49987 14.0195Z" fill="#05A660"/>
                </g>
                <defs>
                  <clipPath id="clip0_7359_55687">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.544922)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="copy-board" (click)="copy('Агзамов Камиль', 1)">
              <div class="copy-board-head">
                ФИО
              </div>
              <div class="">
                Агзамов Камиль
              </div>
              <svg *ngIf="showSvg[1]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 3.5H14.6C16.8402 3.5 17.9603 3.5 18.816 3.93597C19.5686 4.31947 20.1805 4.93139 20.564 5.68404C21 6.53969 21 7.65979 21 9.9V17M6.2 21.5H14.3C15.4201 21.5 15.9802 21.5 16.408 21.282C16.7843 21.0903 17.0903 20.7843 17.282 20.408C17.5 19.9802 17.5 19.4201 17.5 18.3V10.2C17.5 9.07989 17.5 8.51984 17.282 8.09202C17.0903 7.71569 16.7843 7.40973 16.408 7.21799C15.9802 7 15.4201 7 14.3 7H6.2C5.0799 7 4.51984 7 4.09202 7.21799C3.71569 7.40973 3.40973 7.71569 3.21799 8.09202C3 8.51984 3 9.07989 3 10.2V18.3C3 19.4201 3 19.9802 3.21799 20.408C3.40973 20.7843 3.71569 21.0903 4.09202 21.282C4.51984 21.5 5.0799 21.5 6.2 21.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg *ngIf="!showSvg[1]" class="green-mark" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_7359_55687)">
                  <path d="M7.49987 14.0195L4.6082 11.1279C4.2832 10.8029 3.7582 10.8029 3.4332 11.1279C3.1082 11.4529 3.1082 11.9779 3.4332 12.3029L6.91654 15.7862C7.24154 16.1112 7.76654 16.1112 8.09154 15.7862L16.9082 6.96953C17.2332 6.64453 17.2332 6.11953 16.9082 5.79453C16.5832 5.46953 16.0582 5.46953 15.7332 5.79453L7.49987 14.0195Z" fill="#05A660"/>
                </g>
                <defs>
                  <clipPath id="clip0_7359_55687">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.544922)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="blue-info-block mt-2">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_7720_4740)">
                  <path d="M9.16675 6.28874H10.8334V7.9554H9.16675V6.28874ZM10.0001 14.6221C10.4584 14.6221 10.8334 14.2471 10.8334 13.7887V10.4554C10.8334 9.99707 10.4584 9.62207 10.0001 9.62207C9.54175 9.62207 9.16675 9.99707 9.16675 10.4554V13.7887C9.16675 14.2471 9.54175 14.6221 10.0001 14.6221ZM10.0001 2.12207C5.40008 2.12207 1.66675 5.8554 1.66675 10.4554C1.66675 15.0554 5.40008 18.7887 10.0001 18.7887C14.6001 18.7887 18.3334 15.0554 18.3334 10.4554C18.3334 5.8554 14.6001 2.12207 10.0001 2.12207ZM10.0001 17.1221C6.32508 17.1221 3.33341 14.1304 3.33341 10.4554C3.33341 6.7804 6.32508 3.78874 10.0001 3.78874C13.6751 3.78874 16.6667 6.7804 16.6667 10.4554C16.6667 14.1304 13.6751 17.1221 10.0001 17.1221Z" fill="#0D87EF"/>
                </g>
                <defs>
                  <clipPath id="clip0_7720_4740">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.455078)"/>
                  </clipPath>
                </defs>
              </svg>
              <div class="">
                Вы сможете просмотреть всю информацию после принятия заявки в работу
              </div>
            </div>
          </div>
        </p-panel>
        <div class="mb-4"></div>
        <p-panel [toggleable]="true" [collapsed]="true"
                 [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
          <ng-template pTemplate="header">
            <div class="">
              Технические условия
            </div>
            <div class="ml-auto">
              <svg class="minus" width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg class="plus" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.45508V17.4551" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 9.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </ng-template>
          <div class="flex">
            <div class="white-btn">
              Скачать тех. условия
            </div>
          </div>
        </p-panel>
        <div class="mb-4"></div>
        <p-panel [toggleable]="true" [collapsed]="true"
                 [expandIcon]="'custom-minus'" [collapseIcon]="'custom-plus'" [toggler]="'header'">
          <ng-template pTemplate="header">
            <div class="">
              Отправка коммерческого предложения и формы договора
            </div>
            <div class="ml-auto">
              <svg class="minus" width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg class="plus" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1.45508V17.4551" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 9.45508H17" stroke="#363636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </ng-template>
          <div class="blue-info-block">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_7720_4740)">
                <path d="M9.16675 6.28874H10.8334V7.9554H9.16675V6.28874ZM10.0001 14.6221C10.4584 14.6221 10.8334 14.2471 10.8334 13.7887V10.4554C10.8334 9.99707 10.4584 9.62207 10.0001 9.62207C9.54175 9.62207 9.16675 9.99707 9.16675 10.4554V13.7887C9.16675 14.2471 9.54175 14.6221 10.0001 14.6221ZM10.0001 2.12207C5.40008 2.12207 1.66675 5.8554 1.66675 10.4554C1.66675 15.0554 5.40008 18.7887 10.0001 18.7887C14.6001 18.7887 18.3334 15.0554 18.3334 10.4554C18.3334 5.8554 14.6001 2.12207 10.0001 2.12207ZM10.0001 17.1221C6.32508 17.1221 3.33341 14.1304 3.33341 10.4554C3.33341 6.7804 6.32508 3.78874 10.0001 3.78874C13.6751 3.78874 16.6667 6.7804 16.6667 10.4554C16.6667 14.1304 13.6751 17.1221 10.0001 17.1221Z" fill="#0D87EF"/>
              </g>
              <defs>
                <clipPath id="clip0_7720_4740">
                  <rect width="20" height="20" fill="white" transform="translate(0 0.455078)"/>
                </clipPath>
              </defs>
            </svg>
            <div class="">
              Ознакомьтесь с информацией в заявке. При необходимости свяжитесь с заказчиком для уточнения деталей, затем отправьте коммерческое предложение и форму договора
            </div>
          </div>
          <div class="flex">
            <div class="blue-btn mt-4" (click)="agreement = true">
              Отправить
            </div>
          </div>
        </p-panel>
      </div>
    </div>
  </div>
</div>
<div class="tu-dialogs">
  <p-dialog [modal]="true" [(visible)]="rejectApplication" [closable]="true" [style]="{width: '25vw'}">
    <div class="text-center">
      <svg *ngIf="!rejected" width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9999 3.79004C29.2066 3.79004 36.6666 11.2517 36.6666 20.4567C36.6666 29.6617 29.2066 37.1234 19.9999 37.1234C10.7966 37.1234 3.33325 29.6617 3.33325 20.4567C3.33325 11.2517 10.7966 3.79004 19.9999 3.79004ZM27.0833 19.2067H12.9166C12.5851 19.2067 12.2671 19.3384 12.0327 19.5728C11.7983 19.8072 11.6666 20.1252 11.6666 20.4567C11.6666 20.7882 11.7983 21.1062 12.0327 21.3406C12.2671 21.575 12.5851 21.7067 12.9166 21.7067H27.0833C27.4148 21.7067 27.7327 21.575 27.9671 21.3406C28.2016 21.1062 28.3333 20.7882 28.3333 20.4567C28.3333 20.1252 28.2016 19.8072 27.9671 19.5728C27.7327 19.3384 27.4148 19.2067 27.0833 19.2067Z" fill="#D51A52"/>
      </svg>
      <svg *ngIf="rejected" width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7359_19356)">
          <path d="M19.9999 3.78809C10.7999 3.78809 3.33325 11.2548 3.33325 20.4548C3.33325 29.6548 10.7999 37.1214 19.9999 37.1214C29.1999 37.1214 36.6666 29.6548 36.6666 20.4548C36.6666 11.2548 29.1999 3.78809 19.9999 3.78809ZM15.4833 27.6048L9.49992 21.6214C8.84992 20.9714 8.84992 19.9214 9.49992 19.2714C10.1499 18.6214 11.1999 18.6214 11.8499 19.2714L16.6666 24.0714L28.1333 12.6048C28.7833 11.9548 29.8333 11.9548 30.4833 12.6048C31.1333 13.2548 31.1333 14.3048 30.4833 14.9548L17.8333 27.6048C17.1999 28.2548 16.1333 28.2548 15.4833 27.6048Z" fill="#05A660"/>
        </g>
        <defs>
          <clipPath id="clip0_7359_19356">
            <rect width="40" height="40" fill="white" transform="translate(0 0.455078)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="mt-4 text-center dialog-head">
    <span *ngIf="!rejected">
      Отклонить заявку
    </span>
      <span *ngIf="rejected">
      Заявка отклонена!
    </span>
    </div>
    <div class="mt-2 text-center">
    <span *ngIf="!rejected">
      Вы действительно хотите отклонить эту заявку?
    </span>
      <span *ngIf="rejected">
      Вы успешно отклонили заявку
    </span>
    </div>
    <div class="mt-4 dialog-btn-group">
      <div class="blue-btn mb-2" *ngIf="!rejected" (click)="rejected = true">
        Да, отклонить
      </div>
      <div class="blue-btn" *ngIf="rejected" (click)="rejectApplication = false; rejected = false">
        Хорошо
      </div>
      <div class="white-btn" *ngIf="!rejected">
        Отмена
      </div>
    </div>
  </p-dialog>

  <p-dialog [modal]="true" [(visible)]="agreement" [closable]="true" [style]="{width: '25vw'}">

    <div class="text-center dialog-head mb-3">
      Отправка КП и формы договора
    </div>

    <div class="text-sm text-center mb-4">
      Заполните все поля и загрузите файлы
    </div>
    <div class="mb-4">
      <div class="text-sm field-head mb-1">
        Стоимость услуг, ₸
      </div>
      <input class="w-full" type="text" placeholder="Введите стоимость">
    </div>
    <div class="mb-4">
      <div class="text-sm field-head mb-1">
        Срок строительства
      </div>
      <input class="w-full" type="text" placeholder="Например, 3 месяца">
    </div>
    <div class="">
      <div class="text-sm field-head mb-1">
        Загрузите коммерческое предложение
      </div>


      <div *ngIf="!KPfile" class="file-upload" (drop)="onDrop($event, 'kp')"
           (dragover)="onDragOver($event, drg)" (click)="fileInput.click()" #drg>
        <div class="file-upload-head">
          Нажмите, чтоб выбрать файл или перетащите файл в эту область
        </div>
        <div class="add-file" (dragleave)="onDragLeave(drg)">
          Добавить 1 файл
        </div>
        <div class="file-upload-desc">
          Один файл до 30 МБ в формате .pdf
        </div>
        <input type="file" #fileInput (change)="onFileSelected($event,'kp')" accept="application/pdf" hidden>
      </div>

      <div class="file-loaded" *ngIf="KPfile">
        <svg width="29" height="36" viewBox="0 0 29 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9701 1.43994H4.65007C2.81096 1.43994 1.32007 2.93083 1.32007 4.76994V31.4099C1.32007 33.249 2.81096 34.7399 4.65007 34.7399H24.6301C26.4692 34.7399 27.9601 33.249 27.9601 31.4099V11.4299L17.9701 1.43994Z" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17.97 1.43994V11.4299H27.96" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 18H8.4C8.86 18 9.26 18.0533 9.6 18.16C9.94 18.2667 10.22 18.4167 10.44 18.61C10.6667 18.7967 10.8333 19.0233 10.94 19.29C11.0467 19.55 11.1 19.8367 11.1 20.15C11.1 20.4633 11.0467 20.7533 10.94 21.02C10.8333 21.28 10.6667 21.5067 10.44 21.7C10.22 21.8867 9.94 22.0333 9.6 22.14C9.26 22.2467 8.86 22.3 8.4 22.3H6.94V25H6V18ZM8.35 21.48C8.68333 21.48 8.96333 21.45 9.19 21.39C9.42333 21.3233 9.61 21.2333 9.75 21.12C9.89667 21.0067 10 20.8667 10.06 20.7C10.1267 20.5333 10.16 20.35 10.16 20.15C10.16 19.95 10.1267 19.7667 10.06 19.6C10 19.4333 9.89667 19.2933 9.75 19.18C9.61 19.0667 9.42333 18.98 9.19 18.92C8.96333 18.8533 8.68333 18.82 8.35 18.82H6.94V21.48H8.35ZM12.5527 18H14.6027C15.1294 18 15.5994 18.0733 16.0127 18.22C16.4261 18.36 16.7761 18.57 17.0627 18.85C17.3494 19.13 17.5694 19.48 17.7227 19.9C17.8761 20.32 17.9527 20.8033 17.9527 21.35V21.55C17.9527 22.11 17.8727 22.6067 17.7127 23.04C17.5594 23.4667 17.3327 23.8267 17.0327 24.12C16.7394 24.4067 16.3794 24.6267 15.9527 24.78C15.5261 24.9267 15.0427 25 14.5027 25H12.5527V18ZM14.4027 24.18C14.7961 24.18 15.1494 24.13 15.4627 24.03C15.7827 23.93 16.0527 23.7767 16.2727 23.57C16.4927 23.3567 16.6594 23.0833 16.7727 22.75C16.8927 22.4167 16.9527 22.0167 16.9527 21.55V21.35C16.9527 20.9033 16.8961 20.52 16.7827 20.2C16.6694 19.88 16.5061 19.62 16.2927 19.42C16.0794 19.2133 15.8194 19.0633 15.5127 18.97C15.2127 18.87 14.8761 18.82 14.5027 18.82H13.4927V24.18H14.4027ZM19.5547 18H24.0547V18.82H20.4947V21.09H23.7547V21.91H20.4947V25H19.5547V18Z" fill="#0D87EF"/>
        </svg>

        <div class="flex w-full flex-column">
          <div class="file-name">
            {{ KPfile?.name }}
          </div>
          <div class="file-size">
            {{ KPfile?.size | fileSize }}
          </div>
        </div>

        <svg (click)="KPfile = undefined" class="mr-3 cursor-pointer" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 4.6001H2.77778H17" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.9723 4.6C15.9723 4.18579 15.6365 3.85 15.2223 3.85C14.8081 3.85 14.4723 4.18579 14.4723 4.6H15.9723ZM3.52783 4.6C3.52783 4.18579 3.19205 3.85 2.77783 3.85C2.36362 3.85 2.02783 4.18579 2.02783 4.6H3.52783ZM4.6945 4.6C4.6945 5.01421 5.03029 5.35 5.4445 5.35C5.85871 5.35 6.1945 5.01421 6.1945 4.6H4.6945ZM11.8056 4.6C11.8056 5.01421 12.1414 5.35 12.5556 5.35C12.9698 5.35 13.3056 5.01421 13.3056 4.6H11.8056ZM14.4723 4.6V17.2H15.9723V4.6H14.4723ZM14.4723 17.2C14.4723 17.7887 14.0034 18.25 13.4445 18.25V19.75C14.8493 19.75 15.9723 18.5995 15.9723 17.2H14.4723ZM13.4445 18.25H4.55561V19.75H13.4445V18.25ZM4.55561 18.25C3.99674 18.25 3.52783 17.7887 3.52783 17.2H2.02783C2.02783 18.5995 3.1508 19.75 4.55561 19.75V18.25ZM3.52783 17.2V4.6H2.02783V17.2H3.52783ZM6.1945 4.6V2.8H4.6945V4.6H6.1945ZM6.1945 2.8C6.1945 2.21129 6.66341 1.75 7.22228 1.75V0.25C5.81747 0.25 4.6945 1.40048 4.6945 2.8H6.1945ZM7.22228 1.75H10.7778V0.25H7.22228V1.75ZM10.7778 1.75C11.3367 1.75 11.8056 2.21129 11.8056 2.8H13.3056C13.3056 1.40048 12.1826 0.25 10.7778 0.25V1.75ZM11.8056 2.8V4.6H13.3056V2.8H11.8056Z" fill="#B2B7BD"/>
          <path d="M7.22217 9.1001V14.5001" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.7778 9.1001V14.5001" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </div>

    </div>
    <div class="mt-4">
      <div class="text-sm field-head mb-1">
        Загрузите форму договора
      </div>

      <div *ngIf="!Appfile" class="file-upload" (drop)="onDrop($event, 'app')"
           (dragover)="onDragOver($event, drg2)" #drg2
           (click)="fileInput.click()">
        <div class="file-upload-head">
          Нажмите, чтоб выбрать файл или перетащите файл в эту область
        </div>
        <div class="add-file" (dragleave)="onDragLeave(drg2)">
          Добавить 1 файл
        </div>
        <div class="file-upload-desc">
          Один файл до 30 МБ в формате .pdf
        </div>
        <input type="file" #fileInput (change)="onFileSelected($event, 'app')" accept="application/pdf" hidden>
      </div>

      <div class="file-loaded" *ngIf="Appfile">
        <svg width="29" height="36" viewBox="0 0 29 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9701 1.43994H4.65007C2.81096 1.43994 1.32007 2.93083 1.32007 4.76994V31.4099C1.32007 33.249 2.81096 34.7399 4.65007 34.7399H24.6301C26.4692 34.7399 27.9601 33.249 27.9601 31.4099V11.4299L17.9701 1.43994Z" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17.97 1.43994V11.4299H27.96" stroke="#0D87EF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 18H8.4C8.86 18 9.26 18.0533 9.6 18.16C9.94 18.2667 10.22 18.4167 10.44 18.61C10.6667 18.7967 10.8333 19.0233 10.94 19.29C11.0467 19.55 11.1 19.8367 11.1 20.15C11.1 20.4633 11.0467 20.7533 10.94 21.02C10.8333 21.28 10.6667 21.5067 10.44 21.7C10.22 21.8867 9.94 22.0333 9.6 22.14C9.26 22.2467 8.86 22.3 8.4 22.3H6.94V25H6V18ZM8.35 21.48C8.68333 21.48 8.96333 21.45 9.19 21.39C9.42333 21.3233 9.61 21.2333 9.75 21.12C9.89667 21.0067 10 20.8667 10.06 20.7C10.1267 20.5333 10.16 20.35 10.16 20.15C10.16 19.95 10.1267 19.7667 10.06 19.6C10 19.4333 9.89667 19.2933 9.75 19.18C9.61 19.0667 9.42333 18.98 9.19 18.92C8.96333 18.8533 8.68333 18.82 8.35 18.82H6.94V21.48H8.35ZM12.5527 18H14.6027C15.1294 18 15.5994 18.0733 16.0127 18.22C16.4261 18.36 16.7761 18.57 17.0627 18.85C17.3494 19.13 17.5694 19.48 17.7227 19.9C17.8761 20.32 17.9527 20.8033 17.9527 21.35V21.55C17.9527 22.11 17.8727 22.6067 17.7127 23.04C17.5594 23.4667 17.3327 23.8267 17.0327 24.12C16.7394 24.4067 16.3794 24.6267 15.9527 24.78C15.5261 24.9267 15.0427 25 14.5027 25H12.5527V18ZM14.4027 24.18C14.7961 24.18 15.1494 24.13 15.4627 24.03C15.7827 23.93 16.0527 23.7767 16.2727 23.57C16.4927 23.3567 16.6594 23.0833 16.7727 22.75C16.8927 22.4167 16.9527 22.0167 16.9527 21.55V21.35C16.9527 20.9033 16.8961 20.52 16.7827 20.2C16.6694 19.88 16.5061 19.62 16.2927 19.42C16.0794 19.2133 15.8194 19.0633 15.5127 18.97C15.2127 18.87 14.8761 18.82 14.5027 18.82H13.4927V24.18H14.4027ZM19.5547 18H24.0547V18.82H20.4947V21.09H23.7547V21.91H20.4947V25H19.5547V18Z" fill="#0D87EF"/>
        </svg>

        <div class="flex w-full flex-column">
          <div class="file-name">
            {{ Appfile?.name }}
          </div>
          <div class="file-size">
            {{ Appfile?.size | fileSize }}
          </div>
        </div>

        <svg (click)="Appfile = undefined" class="mr-3 cursor-pointer" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 4.6001H2.77778H17" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.9723 4.6C15.9723 4.18579 15.6365 3.85 15.2223 3.85C14.8081 3.85 14.4723 4.18579 14.4723 4.6H15.9723ZM3.52783 4.6C3.52783 4.18579 3.19205 3.85 2.77783 3.85C2.36362 3.85 2.02783 4.18579 2.02783 4.6H3.52783ZM4.6945 4.6C4.6945 5.01421 5.03029 5.35 5.4445 5.35C5.85871 5.35 6.1945 5.01421 6.1945 4.6H4.6945ZM11.8056 4.6C11.8056 5.01421 12.1414 5.35 12.5556 5.35C12.9698 5.35 13.3056 5.01421 13.3056 4.6H11.8056ZM14.4723 4.6V17.2H15.9723V4.6H14.4723ZM14.4723 17.2C14.4723 17.7887 14.0034 18.25 13.4445 18.25V19.75C14.8493 19.75 15.9723 18.5995 15.9723 17.2H14.4723ZM13.4445 18.25H4.55561V19.75H13.4445V18.25ZM4.55561 18.25C3.99674 18.25 3.52783 17.7887 3.52783 17.2H2.02783C2.02783 18.5995 3.1508 19.75 4.55561 19.75V18.25ZM3.52783 17.2V4.6H2.02783V17.2H3.52783ZM6.1945 4.6V2.8H4.6945V4.6H6.1945ZM6.1945 2.8C6.1945 2.21129 6.66341 1.75 7.22228 1.75V0.25C5.81747 0.25 4.6945 1.40048 4.6945 2.8H6.1945ZM7.22228 1.75H10.7778V0.25H7.22228V1.75ZM10.7778 1.75C11.3367 1.75 11.8056 2.21129 11.8056 2.8H13.3056C13.3056 1.40048 12.1826 0.25 10.7778 0.25V1.75ZM11.8056 2.8V4.6H13.3056V2.8H11.8056Z" fill="#B2B7BD"/>
          <path d="M7.22217 9.1001V14.5001" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.7778 9.1001V14.5001" stroke="#B2B7BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </div>

    </div>

    <div class="blue-btn w-full text-center mt-4">
      Отправить
    </div>

  </p-dialog>
</div>

