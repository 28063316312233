<div class="main-table moderator-block bg-white p-3" [ngClass]="{'active' : showBusinessCards}">
  <p-tabView (onChange)="changeFilterTab($event)">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="tab-header">{{'All' |  translate}}
          <span class="count-number">{{count?.count_all}}</span>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="tab-header">{{'New' |  translate}}
          <span class="count-number">{{count?.count_new}}</span>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="tab-header">{{'Published' |  translate}}
          <span class="count-number">{{count?.count_public}}</span>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="tab-header">{{'OnCorrect' |  translate}}
          <span class="count-number">{{count?.count_correct}}</span>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="tab-header">{{'Archival' |  translate}}
          <span class="count-number">{{count?.count_archive}}</span>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="tab-header">{{'Reviews' |  translate}}
          <span class="count-number">{{count?.count_feedback}}</span>
        </div>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <div class="tab-header">{{'companyBusinessCards' |  translate}}
          <span class="count-number">{{count?.count_visit}}</span>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
  <p-progressSpinner *ngIf="loadTable" [class]="'load-spinner-global'"></p-progressSpinner>
  <ng-container *ngIf="!loadTable">
    <ng-container *ngIf="(currentTab == 0 && count.count_all != 0) || (currentTab == 3 && count.count_correct != 0) ||
                         (currentTab == 6 && count.count_visit != 0) || (currentTab == 5 && count.count_feedback != 0) ||
                         (currentTab == 1 && count.count_new != 0) || (currentTab == 2 && count.count_public != 0) ||
                         (currentTab == 4 && count.count_archive != 0)">
      <div class="head-search-bar">
        <div>
          <span class="p-input-icon-left relative" *ngIf="products?.content.length > 0">
            <i class="pi pi-search"></i>
            <input [placeholder]="'Search' | translate" class="main-search" type="text" pInputText [(ngModel)]="searchValue" />
            <img *ngIf="searchValue != ''" (click)="clear()" src="assets/images/main/cross-icon.svg" class="close-icon" alt="">
            <span class="search-txt" (click)="quickSearch()">{{'Find' | translate}}</span>
          </span>
        </div>
        <div (click)="reloadTable()" class="update-table">{{'UpdateTable' | translate}}
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3_17167)">
              <path d="M18.6505 8.35L15.8605 11.14C15.5405 11.46 15.7605 12 16.2105 12H18.0005C18.0005 15.31 15.3105 18 12.0005 18C11.2105 18 10.4405 17.85 9.75052 17.56C9.39052 17.41 8.98052 17.52 8.71052 17.79C8.20052 18.3 8.38052 19.16 9.05052 19.43C9.96052 19.8 10.9605 20 12.0005 20C16.4205 20 20.0005 16.42 20.0005 12H21.7905C22.2405 12 22.4605 11.46 22.1405 11.15L19.3505 8.36C19.1605 8.16 18.8405 8.16 18.6505 8.35ZM6.00052 12C6.00052 8.69 8.69052 6 12.0005 6C12.7905 6 13.5605 6.15 14.2505 6.44C14.6105 6.59 15.0205 6.48 15.2905 6.21C15.8005 5.7 15.6205 4.84 14.9505 4.57C14.0405 4.2 13.0405 4 12.0005 4C7.58052 4 4.00052 7.58 4.00052 12H2.21052C1.76052 12 1.54052 12.54 1.86052 12.85L4.65052 15.64C4.85052 15.84 5.16052 15.84 5.36052 15.64L8.15052 12.85C8.46052 12.54 8.24052 12 7.79052 12H6.00052Z" fill="#0D87EF"/>
            </g>
            <defs>
              <clipPath id="clip0_3_17167">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <p-table [value]="products?.content" [first]="first" [rows]="rows" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr *ngIf="currentTab != 6">
            <th>
              <div class="flex align-items-center gap-2">{{'BIN' | translate}}
                <p-columnFilter [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false" type="text" field="name" display="menu">
                  <ng-template pTemplate="filter">
              <span class="p-input-icon-left relative">
                <i class="pi pi-search"></i>
                <p-inputNumber inputStyleClass="filter-field" styleClass="filter-field" [placeholder]="'Search' | translate" class="filter-field" [format]="false" [(ngModel)]="tableParams.bin"> </p-inputNumber>
              </span>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-between gap">
                      <button class="drop-filter" (click)="clearBin()">{{'Reset' | translate}}</button>
                      <button class="set-filter" (click)="getTableList()">{{'Chose' | translate}}</button>
                    </div>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex align-items-center gap-2">{{'Name' | translate}}
                <p-columnFilter [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false" type="text" field="name" display="menu">
                  <ng-template pTemplate="filter">
              <span class="p-input-icon-left relative">
               <i class="pi pi-search"></i>
               <input [placeholder]="'Search' | translate" class="filter-field" type="text" pInputText [(ngModel)]="tableParams.name" />
              </span>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-between gap">
                      <button class="drop-filter" (click)="clearName()">{{'Reset' | translate}}</button>
                      <button class="set-filter" (click)="getTableList()">{{'Chose' | translate}}</button>
                    </div>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex align-items-center gap-2">{{'CompanyName' | translate}}
              </div>
            </th>
            <th>
              <div class="flex align-items-center gap-2">{{'VendorCode' | translate}}
                <p-columnFilter [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false" type="text" field="name" display="menu">
                  <ng-template pTemplate="filter">
              <span class="p-input-icon-left relative">
                 <i class="pi pi-search"></i>
                 <p-inputNumber inputStyleClass="filter-number" [placeholder]="'Search' | translate" class="filter-field" [format]="false" [(ngModel)]="tableParams.vendorCode"> </p-inputNumber>
              </span>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-between gap">
                      <button class="drop-filter" (click)="clearVendorCode()">{{'Reset' | translate}}</button>
                      <button class="set-filter" (click)="getTableList()">{{'Chose' | translate}}</button>
                    </div>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th *ngIf="currentTab == 0">
              <div class="flex align-items-center gap-2">{{'Status' | translate}}
                <p-columnFilter [showApplyButton]="false" [showClearButton]="false" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                  <ng-template pTemplate="filter">
                    <div class="status-check-wrap">
                      <p-checkbox class="status-check" [binary]="true" [(ngModel)]="checkedAll" (onChange)="checkAll()" [value]="true" inputId="all"></p-checkbox>
                      <label [for]="'all'">{{'All' | translate}}</label>
                    </div>
                    <div *ngFor="let item of statusList" class="status-check-wrap">
                      <p-checkbox (onChange)="check()" class="status-check" name="group1" [value]="item.name" [(ngModel)]="tableParams.stateList" [inputId]="item.id"></p-checkbox>
                      <label [for]="item.id">{{translate.currentLang == 'ru' ? item.name_ru : item.name_kz}}</label>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-between gap">
                      <button class="drop-filter" (click)="clearStatusList()">{{'Reset' | translate}}</button>
                      <button class="set-filter" (click)="getTableList()">{{'Chose' | translate}}</button>
                    </div>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th *ngIf="currentTab != 0">
              <div class="flex align-items-center gap-2">{{'Status' | translate}}</div>
            </th>
            <th>
              <div class="flex align-items-center gap-2">{{"Date" | translate}}</div>
            </th>
          </tr>
          <tr *ngIf="currentTab == 6">
            <th>
              <div class="flex align-items-center gap-2">{{'BIN' | translate}}
                <p-columnFilter [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false" type="text" field="name" display="menu">
                  <ng-template pTemplate="filter">
              <span class="p-input-icon-left relative">
                <i class="pi pi-search"></i>
                <p-inputNumber inputStyleClass="filter-field" styleClass="filter-field" [placeholder]="'Search' | translate" class="filter-field" [format]="false" [(ngModel)]="businessCardsParams.uin"> </p-inputNumber>
              </span>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-between gap">
                      <button class="drop-filter" (click)="clearBin()">{{'Reset' | translate}}</button>
                      <button class="set-filter" (click)="getTableList()">{{'Chose' | translate}}</button>
                    </div>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex align-items-center gap-2">{{'CompanyName' | translate}}
                <p-columnFilter [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false" type="text" field="name" display="menu">
                  <ng-template pTemplate="filter">
              <span class="p-input-icon-left relative">
               <i class="pi pi-search"></i>
               <input [placeholder]="'Search' | translate" class="filter-field" type="text" pInputText [(ngModel)]="businessCardsParams.companyName" />
              </span>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-between gap">
                      <button class="drop-filter" (click)="clearName()">{{'Reset' | translate}}</button>
                      <button class="set-filter" (click)="getTableList()">{{'Chose' | translate}}</button>
                    </div>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex align-items-center gap-2">{{'FieldOfActivity' | translate}}
                <p-columnFilter [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false" type="text" field="name" display="menu">
                  <ng-template pTemplate="filter">
              <span class="p-input-icon-left relative">
                 <i class="pi pi-search"></i>
                 <input [placeholder]="'Search' | translate" class="filter-field" type="text" pInputText [(ngModel)]="businessCardsParams.fieldOfActivity" />
              </span>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-between gap">
                      <button class="drop-filter" (click)="clearVendorCode()">{{'Reset' | translate}}</button>
                      <button class="set-filter" (click)="getTableList()">{{'Chose' | translate}}</button>
                    </div>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex align-items-center gap-2">{{ "Date" | translate }}</div>
            </th>
          </tr>
        </ng-template>
        <ng-container *ngIf="products?.content.length > 0">
          <ng-template pTemplate="body" let-product>
            <tr [routerLink]="['/moderator/card-edit/' + product.id]" *ngIf="currentTab != 6">
              <td class="w-1">{{ product.company_bin }}</td>
              <td class="w-4">{{ translate.currentLang == 'ru' ? product.name_ru : product.name_kz }}</td>
              <td class="w-4">{{ (translate.currentLang == 'ru' ? product.company_name : product.company_name_kz) | shortname}}</td>
              <td class="w-1">{{ product.vendor_code }}</td>
              <td>
                <div class="status-chips">
                  {{ translate.currentLang == 'ru' ? product.state.name_ru : product.state.name_kz }}
                </div>
              </td>
              <td>{{ product.create_date | date:'dd.MM.yy HH:mm' }}</td>
            </tr>
            <tr (click)="getCompanyData(product)" *ngIf="currentTab == 6">
              <td>{{product.bin}}</td>
              <td>{{translate.currentLang == 'ru' ? product.name_ru : product.name_kz}}</td>
              <td>{{translate.currentLang == 'ru' ? product.field_of_activity_ru : product.field_of_activity_kz}}</td>
              <td>{{product.date | date:'dd.MM.yy HH:mm'}}</td>
            </tr>
          </ng-template>
        </ng-container>
      </p-table>
      <div class="table relative" *ngIf="products?.content.length > 0">
          <p-paginator [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} из {totalRecords}"
                       (onPageChange)="changePage($event)" [first]="first" [rows]="rows"
                       [rowsPerPageOptions]="[10, 20, 30]" [totalRecords]="products?.totalElements">
          </p-paginator>
          <div class="show-options">{{"Show" | translate}}</div>
        </div>
    </ng-container>
    <ng-container *ngIf="products?.content.length == 0 || (currentTab == 0 && count.count_all == 0) ||
                         (currentTab == 3 && count.count_correct == 0) ||
                         (currentTab == 6 && count.count_visit == 0) || (currentTab == 5 && count.count_feedback == 0) ||
                         (currentTab == 1 && count.count_new == 0) || (currentTab == 2 && count.count_public == 0) ||
                         (currentTab == 4 && count.count_archive == 0)">
      <div class="block-no-content">
        <div inlineSVG="assets/images/main/no-moderation.svg"></div>
        <div class="text-no-content">{{'thereAreNoNewObjectsForModeration' | translate}}</div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="showBusinessCards">
  <div class="new-block-breadcrumb">
    <div class="home-text" (click)="showBusinessCards = false">{{'MainModeratorWindow' | translate}}</div>
    <div inlineSVG="assets/images/main/right-arrow.svg"></div>
    <div class="moderation-text">{{'companyBusinessCardModeration' | translate}}</div>
  </div>

  <p-progressSpinner *ngIf="loadTable" [class]="'load-spinner-global'"></p-progressSpinner>
  <ng-container *ngIf="!loadTable">
    <div class="title-form">{{'CompanyData' | translate}}</div>
    <form [formGroup]="companyForm">
      <div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">
        <div class="w-3">
          <div class="label-txt">{{'CompanyName' | translate}}</div>
          <div class="relative field-wrap">
            <input readonly [value]="name" class="card-input" type="text" pInputText/>
            <div class="field-eye">
              <img class="cursor-pointer" alt=""
                   [src]="companyForm.get('show_name')?.value ? 'assets/images/main/field-eye-open.svg' : 'assets/images/main/field-eye-closed.svg'">
            </div>
          </div>
        </div>
        <div class="mt-1">
          <svg width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.896484 24L9.10497 1.44738" stroke="#BBC4CB" stroke-linecap="round"/>
          </svg>
        </div>
        <div class="w-3">
          <div>
            <div class="label-txt">{{'IINBIN' | translate}}</div>
            <div class="relative field-wrap">
              <input formControlName="bin" readonly class="card-input" type="text" pInputText/>
              <div class="field-eye">
                <img class="cursor-pointer" alt=""
                     [src]="companyForm.get('show_bin')?.value ? 'assets/images/main/field-eye-open.svg' : 'assets/images/main/field-eye-closed.svg'">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">
        <div class="w-3">
          <div class="label-txt">{{'Tradename' | translate}}
            <span class="text-red-500">*</span>
          </div>
          <div class="relative field-wrap">
            <input readonly formControlName="trade_name_ru" class="card-input" type="text" pInputText/>
          </div>
        </div>
      </div>
      <div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">
        <div class="w-3">
          <div class="label-txt">{{'FieldOfActivity' | translate }}
            <span class="text-red-500">*</span>
          </div>
          <div class="relative field-wrap">
            <input readonly formControlName="field_of_activity_ru" class="card-input" type="text" pInputText/>
          </div>
        </div>
      </div>
      <div class="flex">
        <div>
          <div class="label-txt">{{'logo' | translate}}</div>
          <div class="second-logo-wrap mr-2 relative">
            <img *ngIf="!companyData?.avatar_img_data?.imageUrl" src="assets/images/main/add-file-icon.svg" alt="">
            <img *ngIf="companyData?.avatar_img_data?.imageUrl" class="data-img"
                 [src]="'/proxy/cards-base.marketplace/api/files/' +
                 (companyData?.avatar_img_data?.isTemporaryImage ?
                 'by-fullpath/for-moderation' : 'by-full-path-and-download') +
                  '?fullPath=' + companyData?.avatar_img_data?.imageUrl" alt="">
          </div>
        </div>
        <div>
          <div class="label-txt">{{'banner' | translate}}</div>
          <div class="second-banner-wrap relative">
            <img *ngIf="!companyData?.banner_img_data?.imageUrl" src="assets/images/main/add-file-icon.svg" alt="">
            <img *ngIf="companyData?.banner_img_data?.imageUrl" class="data-img-banner"
                 [src]="'/proxy/cards-base.marketplace/api/files/' +
                 (companyData?.banner_img_data?.isTemporaryImage ?
                 'by-fullpath/for-moderation' : 'by-full-path-and-download') +
                  '?fullPath=' + companyData?.banner_img_data?.imageUrl" alt=""/>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div class="mb-1 comment-head label-txt">{{'ShortDescription' | translate}}
          <span class="text-red-500">*</span>
        </div>
        <div class="mb-3 relative">
          <textarea readonly formControlName="description_ru" maxlength="500" rows="4"
                class="w-6" cols="90" pInputTextarea [placeholder]="'describeWhatYourCompanyDoes' | translate"></textarea>
          <div class="err-txt">{{textLength(companyForm.get('description_ru')?.value)}}/500 {{'Characters' | translate}}</div>
        </div>
      </div>

      <div class="over-header mt-4">{{'LegalAddress' | translate }}</div>
      <div class="field-wrap">
        <div class="label-txt">{{'Address' | translate}}
          <span class="text-red-500">*</span>
        </div>
        <div class="relative w-6 field-wrap">
          <input readonly class="card-input" type="text" pInputText formControlName="street"/>
          <div class="field-eye">
            <img class="cursor-pointer" alt=""
                 [src]="companyForm.get('show_address')?.value ? 'assets/images/main/field-eye-open.svg' : 'assets/images/main/field-eye-closed.svg'">
          </div>
        </div>
      </div>
      <div class="over-header mt-4">{{'ContactData' | translate}}</div>
      <div class="flex w-full align-items-center gap-2" style="padding-right: 3.3rem">
        <div class="w-3">
          <div class="label-txt">{{'PhoneNumber' | translate}}
            <span class="text-red-500">*</span>
          </div>
          <div class="relative field-wrap">
            <input readonly class="card-input" type="text" pInputText formControlName="phone"/>
            <div class="field-eye">
              <img class="cursor-pointer" alt=""
                   [src]="companyForm.get('show_phone')?.value ? 'assets/images/main/field-eye-open.svg' : 'assets/images/main/field-eye-closed.svg'">
            </div>
          </div>
        </div>
        <div class="mt-1">
          <svg width="10" height="25" viewBox="0 0 10 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.896484 24L9.10497 1.44738" stroke="#BBC4CB" stroke-linecap="round"/>
          </svg>
        </div>
        <div class="w-3">
          <div>
            <div class="label-txt">{{'Email'}}
              <span class="text-red-500">*</span>
            </div>
            <div class="relative field-wrap">
              <input readonly class="card-input" type="text" pInputText formControlName="email"/>
            </div>
          </div>
        </div>
      </div>
      <div class="field-wrap pr-3 w-3">
        <div class="label-txt">{{'Site' | translate}}</div>
        <input readonly class="card-input" type="text" pInputText formControlName="website"/>
      </div>
      <div class="over-header mt-4">{{'Social' | translate}}</div>
      <div class="w-6">
        <div class="mb-2 label-txt">{{'WhatsApp'}}
          <span class="text-red-500">*</span>
        </div>
        <input readonly class="card-input mb-3" type="text" pInputText formControlName="whatsapp"/>
        <div class="mb-2 label-txt">{{'Telegram'}}</div>
        <input readonly class="card-input mb-3" type="text" pInputText formControlName="telegram"/>
        <div class="mb-2 label-txt">{{'Instagram'}}</div>
        <input readonly class="card-input mb-3" type="text" pInputText formControlName="instagram"/>
      </div>
      <div class="flex my-3">
        <div>
          <button class="decline-btn" (click)="clickDecline()">{{'Reject' | translate }}</button>
          <button class="adjustment-btn" (click)="clickAdjustment()">{{'SendToCorrect' | translate }}</button>
          <button class="publish-btn" (click)="clickPublish()">{{'Public' | translate }}</button>
        </div>
      </div>
    </form>
  </ng-container>
</ng-container>

<p-dialog [(visible)]="openDeclineModal" [modal]="true" [draggable]="false" [resizable]="false" styleClass="moderation">
  <div class="info-block">
    <ng-container *ngIf="step == 'decline'">
      <div class="info-text">{{'RejectReason1' | translate}}</div>
      <textarea pInputTextarea [(ngModel)]="textModeration" class="info-description"></textarea>
      <button (click)="declineModal()" [disabled]="!textModeration" class="accept-btn w-full justify-content-center" pButton type="button">
        {{'Reject' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="step == 'decline-success'">
      <div inlineSVG="assets/images/main/success.svg"></div>
      <div class="info-text">{{'companyBusinessCardSuccessfullyRejected' | translate}}</div>
      <button (click)="success()" class="accept-btn w-full justify-content-center" pButton type="button">
        {{'Fine' | translate}}
      </button>
    </ng-container>
    <ng-container *ngIf="step == 'adjustment'">
      <div class="info-text">{{'RejectReason1' | translate}}</div>
      <textarea pInputTextarea [(ngModel)]="textModeration" class="info-description"></textarea>
      <button (click)="adjustmentModal()" [disabled]="!textModeration" class="accept-btn w-full justify-content-center" pButton type="button">
        {{'SendToCorrect' | translate}}
      </button>
    </ng-container>
    <ng-container *ngIf="step == 'adjustment-success'">
      <div inlineSVG="assets/images/main/success.svg"></div>
      <div class="info-text">{{'theCompanyBusinessCardHasBeenSuccessfullySentForCorrection' | translate}}</div>
      <button (click)="success()" class="accept-btn w-full justify-content-center" pButton type="button">
        {{'Fine' | translate}}
      </button>
    </ng-container>
    <ng-container *ngIf="step == 'publish'">
      <div inlineSVG="assets/images/main/success.svg"></div>
      <div class="info-text">{{'theCompany\'sBusinessCardHasBeenSuccessfullyPublished' | translate}}</div>
      <button (click)="success()" class="accept-btn w-full justify-content-center" pButton type="button">
        {{'Fine' | translate}}
      </button>
    </ng-container>
  </div>
</p-dialog>
