import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MainService} from "../../../services/main/main.service";
import {HeaderService} from "../../../services/header/header.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-become-partner',
  templateUrl: './become-partner.component.html',
  styleUrls: ['./become-partner.component.sass']
})

export class BecomePartnerComponent implements OnInit {

  items = [
    {label: 'Kazakhtelecom business', url: 'https://www.ismet.kz/ru/katalog-uslug'},
    {label: this.translate.instant('becomeATUPartner'), url: 'https://www.ismet.kz/ru/katalog-uslug#ts'},
    {label: this.translate.instant('registrationAsAPartner')}];
  activeIndex = 1;
  userData: any = [];
  showAddCompany: boolean = false;
  loadSpinner: boolean = false;
  noCompany: boolean = true;
  companyBin: any;
  cardForm!: FormGroup;
  categoryTypes = [
    {
      name: "SERVICE",
      name_kz: "Қызметтер",
      name_ru: "Услуги"
    }
  ];
  categories = [
    {
      name_kz: "Телекоммуникация желілерін салу",
      name_ru: "Строительство сетей телекоммуникаций",
      parent_category: {
        name_kz: "Телекоммуникация желілері",
        name_ru: "Сети телекоммуникаций"
      }
    }
  ];
  regionList: any;
  uploadedFiles: any = [];
  uploadedFilesPDF: any = [];
  uploadedFilesPDFOther: any = [];
  priceType = [
    {
      nameRu: 'Фиксированная',
      nameKz: 'Тұрақты',
      value: true
    },
    {
      nameRu: 'От',
      nameKz: 'Бастап',
      value: false
    }
  ];
  check: boolean = true;
  onCheck: boolean = true;
  informSendingModeration: boolean = false;
  preview: boolean = false;
  disabled: boolean = false;
  disabledOther: boolean = false;
  step: any;
  bigImg: any;
  companyName: any;

  constructor(private fb: FormBuilder, public translate : TranslateService, private route: Router,
              private main: MainService, private headerService: HeaderService) {
  }

  ngOnInit() {
    this.getUserInfo();
  }

  getUserInfo(){
    this.loadSpinner = true;
    this.main.userInfo().subscribe({
      next: (res: any) => {
        this.userData = res;
        res.companyList.filter((main: any) => {
          if (main.mainCompany == true) {
            this.companyBin = main.uin;
          }
        });
        if (this.userData.companyList.length == 0) {
          this.noCompany = false;
          this.userData = [];
        }
        this.loadSpinner = false;
      },
      complete: () => {
      },
      error: () => {
        this.loadSpinner = false;
      }
    });
  }

  cancel() {
    window.open(this.translate.currentLang == 'ru' ? 'https://test.i-smet.kz/ru/katalog-uslug#bp' : 'https://test.i-smet.kz/kk/katalog-uslug#bp', '_self');
  }

  initForm() {
    this.cardForm = new FormGroup({
      bin: new FormControl(this.companyBin),
      name_ru: new FormControl('', Validators.required),
      description_ru: new FormControl('', Validators.required),
      price: new FormControl(null, Validators.required),
      is_fix_price: new FormControl('', Validators.required),
      type: new FormControl('SERVICE'),
      parent_category_id: new FormControl(545, Validators.required),
      category_id: new FormControl(546, Validators.required),
      regions_id: new FormControl([], Validators.required),
      tags: new FormArray([])
    });
  }

  getRegions() {
    this.main.getRegionList().subscribe({
      next: (res: any) => {
        this.regionList = res;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  selectRegion(event: any) {
    this.cardForm.get('regions_id')?.patchValue(event.value);
  }

  removeFileImg(index: number) {
    this.uploadedFiles = this.uploadedFiles.filter((file: any) => file.index != index);
  }

  removeFilePdf() {
    this.disabled = false;
    this.uploadedFilesPDF = [];
  }

  removeFilePdfMas(file: any) {
    this.disabledOther = false;
    this.uploadedFilesPDFOther = this.uploadedFilesPDFOther.filter((item: any) => item !== file);
  }

  onUploadImg(event: any, index: any) {
    for (let file of event.files) {
      this.uploadedFiles.push({file: file, index: index});
    }
  }

  onUploadPdf(event: any) {
    for (let file of event.files) {
      this.uploadedFilesPDF.push({file: file});
    }
    if (this.uploadedFilesPDF.length == 1) {
      this.disabled = true;
    }
  }

  onUploadPdfOther(event: any) {
    this.uploadedFilesPDFOther = [];
    for (let file of event.files) {
      this.uploadedFilesPDFOther.push({file: file});
    }
    if (this.uploadedFilesPDFOther.length == 5) {
      this.disabledOther = true;
    }
  }

  textLength(text: any) {
    return text ? text.length : 0;
  }

  nexStep() {
    this.initForm();
    this.getRegions();
    this.activeIndex = 3;
  }

  public() {
    this.loadSpinner = true;
    let formdata = new FormData();
    formdata.append("createCardRequest", new Blob([JSON.stringify(
      this.cardForm.getRawValue()
      )], {
        type: "application/json"
      }
    ));
    for (let file of this.uploadedFiles) {
      formdata.append('files', file.file)
    }
    for (let file of this.uploadedFilesPDF) {
      formdata.append('files', file.file)
    }
    if (this.uploadedFilesPDFOther.length) {
      for (let file of this.uploadedFilesPDFOther) {
        formdata.append('files', file.file)
      }
    }
    this.main.cardToModeration(formdata).subscribe({
      next: () => {
        this.loadSpinner = false;
        this.informSendingModeration = true;
        this.step = 'success';
      },
      complete: () => {
      },
      error: () => {
        this.loadSpinner = false;
      }
    });
  }

  checkCard() {
    this.informSendingModeration = false;
    let stop = false;
    this.main.cardAvailabilityCheck(this.companyBin).subscribe({
      next: (res: any) => {
        const targetCategory = res.content.find((element: any) => {
          return element.parent_categories.some((elem: any) => {
            return elem.child_categories.some((el: any) => {
              return el.id === 546;
            });
          });
        });

        if (targetCategory && !stop) {
          stop = true;
          this.informSendingModeration = true;
          this.step = 'cardAvailability';
        } else {
          this.activeIndex = 2;
        }
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  selectCompany(company: any){
    this.main.selectCompany(company).subscribe({
      next: () => {
        this.userData.companyList.filter((element: any) => {
          if (element.uin == company) {
            element.mainCompany = true;
            this.headerService.currentCompany = company;
            this.companyName = element;
          } else {
            element.mainCompany = false;
          }
        });
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  personalAccount() {
    this.route.navigate(['account/my-products-and-services']);
    setTimeout(() => {
      window.location.reload();
    },1);
  }

  closeModal() {
    if (this.step == 'cardAvailability') {
      this.personalAccount();
    } else {
      this.cancel();
    }
  }

  loadBigImg() {
    this.preview = true;
    this.bigImg = this.uploadedFiles[0]?.file.objectURL.changingThisBreaksApplicationSecurity;
  }

  previewImg(file: any) {
    this.bigImg = file.file.objectURL.changingThisBreaksApplicationSecurity;
  }
}
