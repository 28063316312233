import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OcpUtils} from "../../../../../OcpUtils";
import {TranslateService} from "@ngx-translate/core";
import {MainService} from "../../../../services/main/main.service";
import {Router} from "@angular/router";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-attaching-a-company',
  templateUrl: './attaching-a-company.component.html',
  styleUrls: ['./attaching-a-company.component.sass']
})
export class AttachingACompanyComponent implements OnInit {
  @Input() hideNotTU: boolean = true;
  @Input() noCompany: boolean = true;
  @Output() continue= new EventEmitter<boolean>();
  @Output() cancel= new EventEmitter<boolean>();

  items = [{ label: 'Маркет', routerLink: '/account/my-products-and-services' },{ label: 'Прикрепление компании'}];
  signButton: string = 'ecp';
  iinForm!: FormGroup;
  nameOfCompany: any;
  errorOfCompany: any;
  error: boolean = false;
  loadSpinner: boolean = false;
  disabledButton: boolean = false;
  openSuccess: boolean = false;
  userId: any;
  qr: any;
  socket: any;
  startSocket: boolean = false;
  requestEnd: boolean = false;
  statGovNotFound: boolean = false;
  SOCKET_URL = 'wss://127.0.0.1:13579/';
  callback = null;
  webSocketInstance: any;

  constructor(private fb: FormBuilder, private message: MessageService, public translate : TranslateService,
              private main: MainService, private router: Router) {
  }

  ngOnInit(){
    let id = localStorage.getItem('company');
    if (id) {
      this.userId = JSON.parse(id);
    }
    this.iinForm = this.fb.group({
      iin: ['', [Validators.required, Validators.pattern(OcpUtils.IIN_PATTERN)]],
    });
  }

  setButton(operator: string) {
    this.signButton = operator;
    switch (this.signButton) {
      case 'ecp':
        if (this.startSocket) {
          this.closeWebSocket();
        }
        break;
      case 'iin':
        if (this.startSocket) {
          this.closeWebSocket();
        }
        break;
      case 'qr':
        this.qrSign();
        break;
    }
  }

  changeIIN() {
    this.error = false;
    this.disabledButton = false;
    this.nameOfCompany = '';
    this.errorOfCompany = '';
    let errorIIN = OcpUtils.validateIin(this.iinForm.get('iin')?.value);
    if (!errorIIN) {
      this.loadSpinner = true;
      this.main.getNameFromStatGov(this.iinForm.get('iin')?.value).subscribe((result: any)  => {
        this.nameOfCompany = this.translate.currentLang == 'ru' ? result.nameRu : result.nameKz;
        this.loadSpinner = false;
        this.disabledButton = true;
      }, () => {
        this.error = true;
        this.errorOfCompany = this.translate.instant('Company not found');
        this.loadSpinner = false;
      });
    } else {
      this.errorOfCompany = this.translate.instant(errorIIN);
      this.error = true;
      this.loadSpinner = false;
    }
  }

  addBMG() {
    this.main.bmgCompany(this.iinForm.get('iin')?.value).subscribe(data => {
      this.openSuccess = true;
    });
  }

  hideForm() {
    if (!this.hideNotTU) {
      this.router.navigate(['/account/my-products-and-services']);
      setTimeout(() => {
        window.location.reload();
      },1);
    } else {
      this.continue.emit();
    }
  }

  goToProducts(){
    this.router.navigate(['/account/my-products-and-services']);
    setTimeout(() => {
      window.location.reload();
    },1);
  }

  ecpSing(result: any){
    let data = {
      "signedXml": result
    }
    this.main.signEcp(data).subscribe(data => {
      this.openSuccess = true;
    });
  }

  qrSign() {
    let data = {
      "userId": this.userId.userId
    }
    this.main.signQR(data).subscribe(data => {
      this.qr = data.qrCode;
      this.initWebSocked(data.websocketURL, data.hash);
    });
  }

  initWebSocked(url: any, hash: any) {
    this.startSocket = false;
    this.requestEnd = false;
    this.statGovNotFound = false;
    this.socket = new WebSocket(url);
    this.socket.onopen = () => {
      this.socket.send(hash);
      this.startSocket = true;
    };
    this.socket.onmessage = (event: any) => {
      try {
        let data = JSON.parse(event.data);
        if (data.status == "OK") {
          this.openSuccess = true;
          this.socket.close();
          this.startSocket = false;
        }
      } catch (e) {
        if (event.data == "REQUEST END") {
          this.socket.close();
          this.startSocket = false;
          this.requestEnd = true;
        }
        if (event.data == "STAT-GOV NOT FOUND") {
          this.socket.close();
          this.startSocket = false;
          this.statGovNotFound = true;
        }
      }
    }
  }

  connect(): Promise<WebSocket> {
    if (this.webSocketInstance && this.webSocketInstance.readyState < 2) {
      return Promise.resolve(this.webSocketInstance);
    }
    return new Promise<WebSocket>((resolve, reject) => {
      this.webSocketInstance = new WebSocket(this.SOCKET_URL);
      this.webSocketInstance.onopen = () => {
        resolve(this.webSocketInstance);
      };
      this.webSocketInstance.onerror = (err: any) => {
        reject(err);
      };
    });
  }

  request() {
    const signInfo = {
      module: 'kz.gov.pki.knca.basics',
      method: 'sign',
      args: {
        allowedStorages: ['PKCS12'],
        format: 'xml',
        data: '<xml>addCompany</xml>',
        signingParams: {decode: 'true', encapsulate: 'true', digested: 'false', tsaProfile: {}},
        signerParams: {
          extKeyUsageOids: [],
          chain: [],
        },
        locale: 'ru'
      }
    };
    this.connect().then(webSocket => {
      webSocket.send(JSON.stringify(signInfo));
      webSocket.onmessage = ({data}) => {
        let response = JSON.parse(data);
        if (response != null) {
          const responseStatus = response['status'];
          if (responseStatus === true) {
            const responseBody = response['body'];
            if (responseBody != null) {
              if (responseBody.hasOwnProperty('result')) {
                this.ecpSing(responseBody.result[0]);
                this.closeWebSocket();
              }
            }
          }
        }
      };
    }).catch(() => {
      this.message.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Запустите или установите NCALayer',
        life: 4000});
    });
  }

  closeWebSocket() {
    if (this.webSocketInstance) {
      this.webSocketInstance.close();
    }
  }
}
