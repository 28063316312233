import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-tu-order',
  templateUrl: './tu-order.component.html',
  styleUrls: ['./tu-order.component.sass']
})
export class TuOrderComponent {

  showSvg: boolean[];

  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor() {
    this.showSvg = new Array(13).fill(true);
  }


  rejectApplication = false;
  rejected = false;

  statusSteps = [
    {
      label: 'Заявка сформирована',
      routerLink: 'personal'
    },
    {
      label: 'Ожидает КП',
      routerLink: 'personal'
    },
    {
      label: 'КП на рассмотрении',
      routerLink: 'personal'
    },
    {
      label: 'Заключение договора',
      routerLink: 'personal'
    },
    {
      label: 'Ожидание подписи заказчика',
      routerLink: 'personal'
    },
    {
      label: 'Договор подписан',
      routerLink: 'personal'
    },
  ];

  activeIndex = 0;

  agreement = false;

  KPfile: any;
  Appfile: any;

  copy(data: any, index: any){
    if(this.showSvg[index]){
      navigator.clipboard.writeText(data);
      this.showSvg[index] = false;
      setTimeout(() => {
        this.showSvg[index] = true;
      }, 2000);
    }
  }

  onFileSelected(event: Event, type: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      if(type == 'app'){
        this.Appfile = file;
      }else{
        this.KPfile = file;
      }

      if (file.type === 'application/pdf') {
        this.uploadFile(file);
      } else {
        alert('Please upload a PDF file.');
      }
    }
  }

  onDragOver(event: DragEvent, drg: any): void {
    event.preventDefault();
    event.stopPropagation();
    drg.classList.add('dragover');
  }

  onDragLeave(drg: any){
    drg.classList.remove('dragover');
  }

  onDrop(event: DragEvent, type: any): void {
    event.preventDefault();
    event.stopPropagation();
    const target = event.currentTarget as HTMLElement;
    target.classList.remove('dragover');

    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];

      if(type == 'app'){
        this.Appfile = file;
      }else{
        this.KPfile = file;
      }

      if (file.type === 'application/pdf') {
        this.uploadFile(file);
      } else {
        alert('Please upload a PDF file.');
      }
      event.dataTransfer.clearData();
    }
  }

  private uploadFile(file: File): void {
    // Здесь можно реализовать логику загрузки файла на сервер
    console.log('Uploading file:', file);
  }

}
