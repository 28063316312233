import {Component, OnInit} from '@angular/core';
import { MenuItem } from 'primeng/api';
import {MainService} from "../../services/main/main.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-moderator',
  templateUrl: './moderator.component.html',
  styleUrls: ['./moderator.component.sass']
})
export class ModeratorComponent implements OnInit {
  items: MenuItem[] | undefined;
  products: any = [];
  searchValue = '';
  statusList: any = [];
  loadTable: boolean = false;
  checkedAll = false;
  tableParams: any = {
    page: 0,
    size: 10,
    stateList: [],
    name: '',
    bin: '',
    vendorCode: '',
    sortBy: 'id',
    sortByAsc: false,
    searchText: ''
  }
  businessCardsParams: any = {
    page: 0,
    size: 10,
    uin: '',
    companyName: '',
    fieldOfActivity: ''
  }
  currentTab: any = 0;
  rows: any = 10;
  first: any = 0;
  showBusinessCards: boolean = false;
  companyForm!: FormGroup;
  name: any;
  companyData: any = [];
  openDeclineModal: boolean = false;
  textModeration: string = '';
  step: any;
  currentCompany: any;
  count: any;

  constructor(private main: MainService, public translate: TranslateService, private fb: FormBuilder) {
  }

  ngOnInit() {
    this.items = [{ label: 'Главное окно модератора' }];
    this.getTableList();
    this.getStatusList();
    this.getTabCount();
  }

  getTabCount(){
    this.main.getTabCount().subscribe({
      next: (res: any) => {
        this.count = res;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  changeFilterTab(event: any) {
    switch (event.index) {
      case 0:
        this.currentTab = 0;
        this.products = [];
        this.first = 0;
        this.rows = 10;
        this.tableParams = {
          page: 0,
          size: 10,
          stateList: [],
          name: '',
          bin: '',
          vendorCode: '',
          sortBy: 'id',
          sortByAsc: false,
          searchText: ''
        }
        this.getTableList();
        break;
      case 1:
        this.currentTab = 1;
        this.products = [];
        this.first = 0;
        this.rows = 10;
        this.tableParams = {
          page: 0,
          size: 10,
          stateList: ["TO_MODERATION"],
          name: '',
          bin: '',
          vendorCode: '',
          sortBy: 'id',
          sortByAsc: false,
          searchText: ''
        }
        this.getTableList();
        break;
      case 2:
        this.currentTab = 2;
        this.products = [];
        this.first = 0;
        this.rows = 10;
        this.tableParams = {
          page: 0,
          size: 10,
          stateList: ["PUBLIC"],
          name: '',
          bin: '',
          vendorCode: '',
          sortBy: 'id',
          sortByAsc: false,
          searchText: ''
        }
        this.getTableList();
        break;
      case 3:
        this.currentTab = 3;
        this.products = [];
        this.first = 0;
        this.rows = 10;
        this.tableParams = {
          page: 0,
          size: 10,
          stateList: ["TO_CORRECT"],
          name: '',
          bin: '',
          vendorCode: '',
          sortBy: 'id',
          sortByAsc: false,
          searchText: ''
        }
        this.getTableList();
        break;
      case 4:
        this.currentTab = 4;
        this.products = [];
        this.first = 0;
        this.rows = 10;
        this.tableParams = {
          page: 0,
          size: 10,
          stateList: ["ARCHIVE"],
          name: '',
          bin: '',
          vendorCode: '',
          sortBy: 'id',
          sortByAsc: false,
          searchText: ''
        }
        this.getTableList();
        break;
      case 5:
        this.currentTab = 5;
        this.products = [];
        this.first = 0;
        this.rows = 10;
        this.tableParams = {
          page: 0,
          size: 10,
          stateList: [],
          name: '',
          bin: '',
          vendorCode: '',
          sortBy: 'id',
          sortByAsc: false,
          searchText: ''
        }
        this.getReviewList();
        break;
      case 6:
        this.currentTab = 6;
        this.products = [];
        this.first = 0;
        this.rows = 10;
        this.businessCardsParams = {
          page: 0,
          size: 10,
          uin: '',
          companyName: '',
          fieldOfActivity: ''
        }
        this.receivingBusinessCards();
        break;
    }
  }

  getReviewList(){
    this.loadTable = true;
    this.main.reviewList(this.tableParams).subscribe({
      next: (res: any) => {
        this.products = res;
        this.loadTable = false;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  reloadTable() {
    this.tableParams = {
      page: 0,
      size: 10,
      stateList: [],
      name: '',
      bin: '',
      vendorCode: '',
      sortBy: 'id',
      sortByAsc: false,
      searchText: ''
    }
    this.getTableList();
  }

  getTableList(){
    if (this.currentTab == 5) {
      this.getReviewList();
    } else if (this.currentTab == 6) {
      this.receivingBusinessCards();
    } else {
      this.loadTable = true;
      this.main.moderatorTableList(this.tableParams).subscribe({
        next: (res: any) => {
          this.products = res;
          this.loadTable = false;
        },
        complete: () => {
        },
        error: () => {
          this.loadTable = false;
        }
      });
    }
  }

  quickSearch(){
    this.tableParams = {
      page: 0,
      size: 10,
      stateList: [],
      name: '',
      bin: '',
      vendorCode: '',
      sortBy: 'id',
      sortByAsc: false,
      searchText: this.searchValue
    }
    this.getTableList();
  }

  clearStatusList(){
    this.tableParams.stateList = [];
    this.checkedAll = false;
    this.getTableList();
  }

  clearBin(){
    this.tableParams.bin = "";
    this.businessCardsParams.uin = "";
    this.getTableList();
  }

  clearVendorCode(){
    this.businessCardsParams.fieldOfActivity = "";
    this.tableParams.vendorCode = "";
    this.getTableList();
  }

  clearName(){
    this.businessCardsParams.companyName = "";
    this.tableParams.name = "";
    this.getTableList();
  }

  clear(){
    this.searchValue = '';
    this.quickSearch();
  }

  checkAll(){
    if (this.checkedAll) {
      this.tableParams.stateList = this.checkBufferArray().slice();
    } else {
      this.tableParams.stateList = [];
    }
  }

  check(){
    this.checkedAll = this.checkBufferArray().length == this.tableParams.stateList.length;
  }

  checkBufferArray(){
    let buffer:any = [];
    this.statusList.forEach((element: any) => {
      buffer.push(element.name);
    })
    return buffer;
  }

  changePage(event: any){
    this.first = event.first;
    this.rows = event.rows;
    this.tableParams.page = event.first / event.rows;
    this.tableParams.size = event.rows;
    this.getTableList();
  }

  getStatusList(){
    this.main.statusList().subscribe({
      next: (res: any) => {
        this.statusList = res;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  receivingBusinessCards(){
    this.products = [];
    this.loadTable = true;
    this.main.receivingAllCompanyBusinessCards(this.businessCardsParams).subscribe({
      next: (res: any) => {
        this.products = res;
        this.openDeclineModal = false;
        this.showBusinessCards = false;
        this.textModeration = "";
        this.loadTable = false;
      },
      complete: () => {
      },
      error: () => {
        this.loadTable = false;
      }
    })
  }

  success() {
    this.receivingBusinessCards();
    this.getTabCount();
  }

  getCompanyData(card: any) {
    this.currentCompany = card;
    this.main.getPublicCompanyInfoForModerator(card.bin).subscribe({
      next: (res: any) => {
        this.companyData = res;
        this.companyForm = this.fb.group({
          bin: [res.bin],
          street: [res.street],
          description_ru: [res.description_ru],
          trade_name_ru: [res.trade_name_ru],
          field_of_activity_ru: [res.field_of_activity_ru],
          phone: [res.phone],
          website: [res.website ? res.website : ''],
          email: [res.email],
          whatsapp: [res.whatsapp],
          telegram: [res.telegram ? res.telegram : ''],
          instagram: [res.instagram ? res.instagram : ''],
          show_name: [res.show_name],
          show_bin: [res.show_bin],
          show_phone: [res.show_phone],
          show_address: [res.show_address]
        });
        this.name = this.translate.currentLang == 'ru' ? res.name_ru : res.name_kz;
        this.showBusinessCards = true;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  textLength(text: any) {
    return text ? text.length : 0;
  }

  clickDecline() {
    this.openDeclineModal = true;
    this.step = 'decline';
  }

  clickAdjustment() {
    this.openDeclineModal = true;
    this.step = 'adjustment';
  }

  adjustmentModal() {
    this.loadTable = true;
    this.addComment();
    this.main.adjustmentCard(this.currentCompany.id, this.textModeration).subscribe({
      next: () => {
        this.openDeclineModal = true;
        this.step = 'adjustment-success';
        this.loadTable = false;
      },
      complete: () => {
      },
      error: () => {
        this.loadTable = false;
      }
    });
  }

  clickPublish() {
    this.loadTable = true;
    this.main.publishCard(this.currentCompany.id).subscribe({
      next: () => {
        this.openDeclineModal = true;
        this.step = 'publish';
        this.loadTable = false;
      },
      complete: () => {
      },
      error: () => {
        this.loadTable = false;
      }
    });
  }

  declineModal() {
    this.loadTable = true;
    this.addComment();
    this.main.declineCard(this.currentCompany.id, this.textModeration).subscribe({
      next: () => {
        this.step = 'decline-success';
        this.loadTable = false;
      },
      complete: () => {
      },
      error: () => {
        this.loadTable = false;
      }
    });
  }

  addComment() {
    this.main.addCommentModeration(this.currentCompany.id, this.textModeration).subscribe({
      next: () => {
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }
}
