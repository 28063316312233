<div class="grid-block" *ngIf="!onlyRouterShow" [ngClass]="{'account-bg': greyPages()}">
  <app-header [notAvailable]="notAvailable" [ngClass]="{'mobile-hidden' : pathCheck()}"></app-header>

  <ng-container *ngIf="notAvailable">
    <app-site-unavailable></app-site-unavailable>
  </ng-container>

  <ng-container *ngIf="!notAvailable">
    <div class="main">
      <div class="i-container">
        <div class="i-row">
          <div class="home-content">
            <router-outlet></router-outlet>
            <app-footer></app-footer>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<router-outlet *ngIf="onlyRouterShow"></router-outlet>
