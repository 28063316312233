<div class="top-block mt-4">
  <div class="top-left-part">
    <div class="main-wrapper">
      <div class="main-header">
        {{ 'Мы помогаем компаниям находить друг друга!' | translate }}
      </div>
      <div class="main-desc">
        {{ 'Присоединяйтесь к готовой базе потенциальных клиентов, совершайте выгодные сделки, находите надёжных партнёров и развивайте свой бизнес' | translate }}
      </div>
    </div>
    <div class="">
      <img src="assets/images/main/top-main-page-image.svg" alt="">
    </div>
  </div>
  <div class="top-right-part">
    <div class="part-header mb-3" [ngClass]="{'text-4xl': translate.currentLang == 'kz'}">
      {{ 'Хочу купить' | translate }}
    </div>
    <div class="part-desc">
      {{ 'Введите название товара или услуги для получения лучших предложений' | translate }}
    </div>
    <div class="mb-3">
      <input class="w-full" [placeholder]="'Что хотите купить?' | translate" [(ngModel)]="searchService._name">
    </div>
    <div class="search-partners" (click)="findPartner()" [routerLink]="'/search'">
      {{ 'Найти партнёров' | translate }}
    </div>
  </div>
</div>
<!-- id категорий сделаны хард кодом, если бэк поменяет то надо менять тут-->
<div class="category-types">
    <div class="category-type-item" (click)="type = 1" [ngClass]="{'active': type == 1}">
        {{ 'Товары' | translate }}
    </div>
    <div class="category-type-item" (click)="type = 2" [ngClass]="{'active': type == 2}">
        {{ 'Услуги' | translate }}
    </div>
</div>
<div class="" *ngIf="type == 1">
    <div class="category-block">
        <div (click)="goToCategory(headerService._catalog[0].parentlist[5], 2, 5,2, headerService._catalog[0].parentlist, 1)"  class="category-item">
            <div class="category-name">
                {{ 'Авто и транспорт' | translate }}
            </div>
            <img src="assets/images/categories-block/1.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[0].parentlist[3], 2, 3,2,headerService._catalog[0].parentlist, 1)" class="category-item">
            <div class="category-name">
                {{ 'Красота и здоровье' | translate }}
            </div>
            <img src="assets/images/categories-block/2.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[0].parentlist[0], 2, 0,2,headerService._catalog[0].parentlist, 1)" class="category-item">
            <div class="category-name">
                {{ 'Техника и электроника' | translate }}
            </div>
            <img src="assets/images/categories-block/3.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[0].parentlist[1].child_categories[3],3,3,3,headerService._catalog[0].parentlist[1].child_categories, 1, true)" class="category-item">
            <div class="category-name">
                {{ 'Системы безопасности' | translate }}
            </div>
            <img src="assets/images/categories-block/4.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[0].parentlist[2], 2, 2,2,headerService._catalog[0].parentlist, 1)" class="category-item">
            <div class="category-name">
                {{ 'Сеть и телекоммуникации' | translate }}
            </div>
            <img src="assets/images/categories-block/5.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[0].parentlist[4], 2, 4,2,headerService._catalog[0].parentlist, 1)" class="category-item">
            <div class="category-name">
                {{ 'Офис и строительство' | translate }}
            </div>
            <img src="assets/images/categories-block/6.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[0].parentlist[8], 2, 8,2,headerService._catalog[0].parentlist, 1)" class="category-item">
            <div class="category-name">
                {{ 'Сельское хозяйство и продукты питания' | translate }}
            </div>
            <img src="assets/images/categories-block/7.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[0].parentlist[6], 2, 6,2,headerService._catalog[0].parentlist, 1)" class="category-item">
            <div class="category-name">
                {{ 'Оборудование и инструменты' | translate }}
            </div>
            <img src="assets/images/categories-block/8.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[0].parentlist[7], 2, 7,2,headerService._catalog[0].parentlist, 1)" class="category-item">
            <div class="category-name">
                {{ 'Химия, металл, пластик' | translate }}
            </div>
            <img src="assets/images/categories-block/9.png" alt="">
        </div>
    </div>
</div>

<div class="" *ngIf="type == 2">
    <div class="category-block">
        <div (click)="goToCategory(headerService._catalog[1].parentlist[3],2,3,2,headerService._catalog[1].parentlist, 2)"  class="category-item">
            <div class="category-name">
                {{ 'Бытовые услуги' | translate }}
            </div>
            <img src="assets/images/categories-block/10.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[1].parentlist[8],2,8,2,headerService._catalog[1].parentlist, 2)" class="category-item">
            <div class="category-name">
                {{ 'Реклама и дизайн' | translate }}
            </div>
            <img src="assets/images/categories-block/11.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[1].parentlist[9],2,9,2,headerService._catalog[1].parentlist, 2)" class="category-item">
            <div class="category-name">
                {{ 'Медицина и страхование' | translate }}
            </div>
            <img src="assets/images/categories-block/12.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[1].parentlist[5],2,5,2,headerService._catalog[1].parentlist, 2)" class="category-item">
            <div class="category-name">
                {{ 'Охранные и аварийные службы' | translate }}
            </div>
            <img src="assets/images/categories-block/13.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[1].parentlist[6],2,6,2,headerService._catalog[1].parentlist, 2)" class="category-item">
            <div class="category-name">
                {{ 'Аутсорсинг' | translate }}
            </div>
            <img src="assets/images/categories-block/14.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[1].parentlist[7],2,7,2,headerService._catalog[1].parentlist, 2)" class="category-item">
            <div class="category-name">
                {{ 'IT-Услуги' | translate }}
            </div>
            <img src="assets/images/categories-block/15.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[1].parentlist[1],2,1,2,headerService._catalog[1].parentlist, 2)" class="category-item">
            <div class="category-name">
                {{ 'Обучение и тренинги' | translate }}
            </div>
            <img src="assets/images/categories-block/16.png" alt="">
        </div>
        <div (click)="goToCategory(headerService._catalog[1].parentlist[0],2,0,2,headerService._catalog[1].parentlist, 2)" class="category-item">
            <div class="category-name">
                {{ 'Строительство сетей' | translate }}
            </div>
            <img src="assets/images/categories-block/17.png" alt="">
        </div>
    </div>
</div>
