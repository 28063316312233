<div class="search-tu">
  <input type="text" placeholder="Начните вводить номер заявки или заказчика">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7359_4482)">
      <path d="M12.9166 11.6662H12.2583L12.025 11.4412C13.025 10.2745 13.5416 8.68288 13.2583 6.99121C12.8666 4.67455 10.9333 2.82454 8.59997 2.54121C5.07497 2.10788 2.10831 5.07454 2.54164 8.59955C2.82497 10.9329 4.67497 12.8662 6.99164 13.2579C8.68331 13.5412 10.275 13.0245 11.4416 12.0245L11.6666 12.2579V12.9162L15.2083 16.4579C15.55 16.7995 16.1083 16.7995 16.45 16.4579C16.7916 16.1162 16.7916 15.5579 16.45 15.2162L12.9166 11.6662ZM7.91664 11.6662C5.84164 11.6662 4.16664 9.99121 4.16664 7.91621C4.16664 5.84121 5.84164 4.16621 7.91664 4.16621C9.99164 4.16621 11.6666 5.84121 11.6666 7.91621C11.6666 9.99121 9.99164 11.6662 7.91664 11.6662Z" fill="#99A5B1"/>
    </g>
    <defs>
      <clipPath id="clip0_7359_4482">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</div>

<div *ngFor="let item of tuList" class="order-wrap" [routerLink]="'/tu-order/' + item.id">
  <div class="order-number">
    Заявка № {{ item?.id }}
    <svg class="ml-auto" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.00002 13.0003L16.17 13.0003L11.29 17.8803C10.9 18.2703 10.9 18.9103 11.29 19.3003C11.68 19.6903 12.31 19.6903 12.7 19.3003L19.29 12.7103C19.68 12.3203 19.68 11.6903 19.29 11.3003L12.7 4.71027C12.31 4.32027 11.68 4.32027 11.29 4.71027C10.9 5.10027 10.9 5.73027 11.29 6.12027L16.17 11.0003L5.00002 11.0003C4.45002 11.0003 4.00002 11.4503 4.00002 12.0003C4.00002 12.5503 4.45002 13.0003 5.00002 13.0003Z"/>
    </svg>
  </div>
  <div class="flex align-items-start">
    <div class="order-col">
      <div class="order-col-head">
        Дата оформления
      </div>
      <div class="">
        {{ item?.create_date	| date: 'dd.MM.yy HH:mm'}}
      </div>
    </div>
    <div class="order-col">
      <div class="order-col-head">
        Последние изменения
      </div>
      <div class="">
        {{ item?.last_update_date		| date: 'dd.MM.yy HH:mm'}}
      </div>
    </div>
    <div class="order-col">
      <div class="order-col-head">
        Заказчик
      </div>
      <div class="">
        {{ item?.user_company_name | shortname }}
      </div>
    </div>
    <div class="order-col">
      <div class="order-col-head">
        Город
      </div>
      <div class="">
        {{ item?.region.region_name_ru }}
      </div>
    </div>
    <div class="order-col">
      <div class="order-col-head">
        Статус
      </div>
      <div class="status-type blue-type">
        {{ item.name_ru	}}
      </div>
    </div>
    <div class="btn-col">
      <div (click)="takeToWork(item.id)" class="apply-btn">
        Принять в работу
      </div>
    </div>
  </div>
</div>

<app-pagination [totalElements]="tuList?.totalElements" [totalPages]="tuList?.totalPages" (pageChanged)="getTUList()"></app-pagination>
