import {Component} from '@angular/core';
import {MainService} from "../../../services/main/main.service";
import {TranslateService} from "@ngx-translate/core";
import {MessageService} from "primeng/api";
import {HeaderService} from "../../../services/header/header.service";
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.sass']
})
export class SalesComponent {
  products: any;
  items: any;
  currentProduct: any;
  shortData = false;
  employee: any;
  loading = false;
  loadTable = true;
  tuIsAvailable = false;
  fullData = false;
  rejectModal = false;
  closeApplication = false;
  private fileName = 'tu.pdf';
  tableParams: any = {
    page: 0,
    size: 10,
    stateList: [],
    name: '',
    bin: '',
    vendorCode: '',
    sortBy: 'id',
    sortByAsc: true,
    searchText: ''
  }
  rejectComment: any = [];
  tuId: any;
  employees: any;
  companyBin: any;
  pmenu: any = [];
  employeeModal = false;
  stateModeration: any;
  tuData = false;
  tuInWork = false;
  step: any;

  constructor(private main: MainService, private message: MessageService, public translate: TranslateService,
              private headerService: HeaderService, private http: HttpClient) {
  }

  ngOnInit() {
    if (this.headerService.currentCompany) {
      this.companyBin = this.headerService.currentCompany;
      this.getCompanyData();
    }
    this.headerService.companyChangeEmitter.subscribe(company => {
      this.companyBin = this.headerService.currentCompany;
      this.getCompanyData();
    });
    this.menuItems();
  }

  clear() {
    this.tableParams.searchText = '';
    this.getProducts();
  }

  quickSearch() {
    this.getProducts();
  }

  menuItems() {
    this.pmenu[0] = [
      {
        label: '',
        id: 'Look',
        command: (): any => {
          this.shortData = true;
        }
      }
    ];
    this.pmenu[1] = [
      {
        label: '',
        id: 'Look',
        command: (): any => {
          this.fullData = true;
        }
      },
      {
        label: '',
        id: 'Delegate',
        command: (): any => {
          this.employeeModal = true;
          this.employeeList();
        }
      }
    ];
    this.pmenu[2] = [
      {
        label: '',
        id: 'Look',
        command: (): any => {
          this.fullData = true;
        }
      }
    ];
    this.pmenu[3] = [
    ];
    this.pmenu[4] = [
      {
        label: '',
        id: 'Look',
        command: (): any => {
          this.fullData = true;
        }
      }
    ];
    this.pmenu[5] = [
      {
        label: '',
        id: 'Look',
        command: (): any => {
          this.tuData = true;
          if (this.currentProduct?.state?.id == 1) {
            this.tuInWork = true;
            this.step = 'shown';
          } else if (this.currentProduct?.state?.id == 0) {
            this.tuInWork = false;
            this.step = 'hidden';
          } else {
            this.tuInWork = false;
            this.step = 'shown';
          }
          this.getTUFileId();
        }
      }
    ];

  }

  getTUFileId() {
    this.main.getTUId(this.currentProduct.id).subscribe({
      next: (res: any) => {
        this.tuId = res;
        this.tuIsAvailable = true;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  changeLang(product: any) {
    this.pmenu.forEach((item: any) => {
      item.forEach((subItem: any) => {
        this.translate.get('' + subItem.id).subscribe((translate: any) => {
          subItem.label = translate;
        })
      });
    });
    this.currentProduct = product;
  }

  delegateApp() {
    this.main.delegateApplication(this.currentProduct.id, this.employee).subscribe({
      next: () => {
        this.getProducts();
        this.close();
      },
      complete: () => {
      },
      error: (err: any) => {
        this.message.add({
          severity: 'error',
          summary: 'Error',
          detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz, life: 4000
        });
      }
    });
  }

  close() {
    this.rejectModal = false;
    this.fullData = false;
    this.shortData = false;
    this.closeApplication = false;
    this.employeeModal = false;
    this.tuData = false;
    this.tuIsAvailable = false;
  }

  changePage(event: any) {
    this.loadTable = true;
    this.tableParams.page = event.page;
    this.tableParams.size = event.rows;
    this.getProducts();
  }

  getProducts() {
    this.main.getSells(this.tableParams).subscribe({
      next: (res: any) => {
        this.products = res;
        this.loadTable = false;
      },
      complete: () => {
      },
      error: () => {
      }
    });
  }

  getToWork(categoryId: any = 0) {
    this.main.takeToWork(this.currentProduct.id).subscribe({
      next: () => {
        if (categoryId == 546) {
          this.tuInWork = true;
          this.step = 'shown';
        } else {
          this.shortData = false;
          this.fullData = true;
        }
        this.getProducts();
      },
      complete: () => {
      },
      error: (err: any) => {
        this.message.add({
          severity: 'error',
          summary: 'Error',
          detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz, life: 4000
        });
      }
    });
  }

  rejectApp() {
    this.main.rejectApplication(this.currentProduct.id, this.rejectComment).subscribe({
      next: () => {
        this.getProducts();
        this.close();
      },
      complete: () => {
      },
      error: (err: any) => {
        this.message.add({
          severity: 'error',
          summary: 'Error',
          detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz, life: 4000
        });
      }
    });
  }

  downloadFileBlob(url: string): Observable<Blob> {
    return this.http.get(url, {responseType: 'blob'});
  }

  downloadFile(url: any): void {
    this.downloadFileBlob(url).subscribe((blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = this.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  employeeList() {
    this.main.getEmployeeList().subscribe({
      next: (res: any) => {
        this.employees = res;
      },
      complete: () => {
      },
      error: (err: any) => {
        this.message.add({
          severity: 'error',
          summary: 'Error',
          detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz, life: 4000
        });
      }
    });
  }

  appoint() {
    this.employeeList();
    this.employeeModal = true;
  }

  getCompanyData() {
    this.main.getCompanyInfo(this.companyBin).subscribe({
      next: (res: any) => {
        this.stateModeration = res.state.id;
        this.getProducts();
      },
      complete: () => {
      },
      error: (err: any) => {
        this.message.add({
          severity: 'error',
          summary: 'Error',
          detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz,
          life: 4000
        });
      }
    });
  }

  completeOrder() {
    if (this.currentProduct.card_category_dto.category_id == 331) {
      this.main.takeToWork(this.currentProduct.id).subscribe({
        next: () => {
          this.main.completeOrder(this.currentProduct.id).subscribe({
            next: () => {
              this.getProducts();
              this.close();
            },
            complete: () => {
            },
            error: (err: any) => {
              this.message.add({
                severity: 'error',
                summary: 'Error',
                detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz,
                life: 4000
              });
            }
          });
        },
        complete: () => {
        },
        error: (err: any) => {
          this.message.add({
            severity: 'error',
            summary: 'Error',
            detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz, life: 4000
          });
        }
      });
    } else {
      this.main.completeOrder(this.currentProduct.id).subscribe({
        next: () => {
          this.getProducts();
          this.close();
        },
        complete: () => {
        },
        error: (err: any) => {
          this.message.add({
            severity: 'error',
            summary: 'Error',
            detail: this.translate.currentLang == 'ru' ? err.error.description_ru : err.error.description_kz, life: 4000
          });
        }
      });
    }
  }
}
